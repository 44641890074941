import React, { useState } from "react";
import Select from "./Components/Select";
import { NavLink } from "react-router-dom";
import TaskListMessage from "./Components/TaskListMessage";
import QuestionSelect from "./Components/QuestionSelect";
import TakeNoteMessage from "./Components/TakeNoteMessage";
import { SimpleAccordion, LawAccordion } from "./Components/Accordion";
import Grid from "@material-ui/core/Grid";

const DeleteList = (props) => {
  const [yesResponseFlag, yesVisibilty] = useState(false);
  const [idkResponseFlag, idkVisibility] = useState(false);
  const [doLaterFlag, doLaterVis] = useState(false);
  const [continueFLag, continueVisitbility] = useState(false);
  const [listFlag, listFlagVis] = useState(false);
  const allPassedProps = props.location.state;
  const name = allPassedProps.p_name.name;
  const tasksToDelegate = allPassedProps.tasks.tasksToDelegate;
  const agendaEstateAttorney = allPassedProps.estateTasks.agendaEstateAttorney;
  const socialAccountsToDelete = [];
  const emailAccountsToDelete = [];

  function initalDeleteHelper(answer) {
    if (answer === "Yes") {
      yesVisibilty(true);
      idkVisibility(false);
      continueVisitbility(false);
    } else if (answer === "No") {
      yesVisibilty(false);
      idkVisibility(false);
      continueVisitbility(true);
    } else if (answer === "Select") {
      yesVisibilty(false);
      idkVisibility(false);
      continueVisitbility(false);
    } else {
      yesVisibilty(false);
      idkVisibility(true);
      continueVisitbility(true);
    }
    listFlagVis(false);
    doLaterVis(false);
  }

  function listHelper(answer) {
    if (
      answer ===
      "Yes - Enter accounts now to add specific account actions to your Task List"
    ) {
      listFlagVis(true);
      continueVisitbility(true);
      doLaterVis(false);
    } else if (answer === "Select") {
      listFlagVis(false);
      continueVisitbility(false);
      doLaterVis(false);
    } else {
      doLaterVis(true);
      listFlagVis(false);
      continueVisitbility(true);
    }
  }

  const welcomeMessage = () => (
    <div class="jumbotron jumbotron-fluid">
      <div class="container">
        <h1 class="display-4">Deleting Online Accounts</h1>
        <p class="lead">
          Many things we do online are temporary or meet a need that does not
          exist after we die. These types of accounts often do not need to be
          preserved after the account owner's death. Deleting online accounts
          after someone dies can help prevent confusion and identity theft.
        </p>
      </div>
    </div>
  );

  function gatherData() {
    if (idkResponseFlag === true) {
      tasksToDelegate.push(
        "Look for a list of online accounts to Delete on Death"
      );
    }

    if (doLaterFlag === true) {
      tasksToDelegate.push("Review Delete on Death list");
    }
    if (tasksToDelegate.length === 2) {
      tasksToDelegate.pop();
    }
  }

  const deleteListQuestionComponent = () => (
    <QuestionSelect
      question={
        "Did " + name + " leave a list of online accounts to Delete on Death ?"
      }
      options={["Select", "Yes", "No", "I don't know"]}
      function={initalDeleteHelper}
    />
  );

  const educationalPopupComponent = () => (
    <div>
      <TakeNoteMessage message="Consider talking to an attorney before you request deletion of online accounts that may contain information necessary to administer the estate. (e.g. bank account information, lists of assets, contacts etc.) Currently laws limit what you can request from online communication platforms after someone dies; however if the account is deleted too soon, even less will be available."></TakeNoteMessage>
      <LawAccordion />
    </div>
  );

  const yesResponseComponent = () => (
    <QuestionSelect
      question={
        "Do you want to add deletion of those accounts to your Task List ?"
      }
      options={[
        "Select",
        "Yes - Enter accounts now to add specific account actions to your Task List",
        "I'll do that later",
      ]}
      function={listHelper}
    />
  );

  const idkResponseComponent = () => (
    <TaskListMessage task={"Look for a list of accounts to Delete on Death"} />
  );

  const doLaterComponent = () => (
    <TaskListMessage task={"Review Delete on Death list"} />
  );

  const continueButton = () => (
    <div class="form-group">
      <NavLink
        to={{
          pathname: "/MediaType",
          state: {
            p_name: { name },
            tasks: { tasksToDelegate },
            estateTasks: { agendaEstateAttorney },
            socialDeleteAccounts: { socialAccountsToDelete },
            emailDeleteAccounts: { emailAccountsToDelete },
          },
        }}
      >
        <button
          type="button"
          class="btn btn-dark btn-lg"
          onClick={gatherData()}
        >
          Continue
        </button>
      </NavLink>
    </div>
  );

  const deleteCheckList = () => (
    <div>
      <Select
        selection="social"
        description="Select all Social Media accounts that would need to be deleted"
        accountsList={socialAccountsToDelete}
      />
      <Select
        selection="email"
        description="Select all Email accounts that would need to be deleted"
        accountsList={emailAccountsToDelete}
      />
      <br />
    </div>
  );

  return (
    <div>
      <div class>{welcomeMessage()}</div>
      <div class="col-md-9">
        <form>
          {deleteListQuestionComponent()}
          {yesResponseFlag ? educationalPopupComponent() : ""}
          {yesResponseFlag ? yesResponseComponent() : ""}
          {idkResponseFlag ? idkResponseComponent() : ""}
          {doLaterFlag ? doLaterComponent() : ""}
          {listFlag ? deleteCheckList() : ""}
        </form>
      </div>
      <div class="text-center"> {continueFLag ? continueButton() : ""}</div>
    </div>
  );
};

export default DeleteList;
