import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SimpleAccordion from "./Components/Accordion";
import Select from "./Components/Select";
import QuestionSelect from "./Components/QuestionSelect";
import TaskListMessage from "./Components/TaskListMessage";
import TakeNoteMessage from "./Components/TakeNoteMessage";

export const PhotoQuestions = (props) => {
  const [digiFormatEduFlag, digiFormatEduVis] = useState(false);
  const [websiteFormatEduFlag, websiteFormatVis] = useState(false);
  const [haveLookedDriveFlag, haveLookedDriveVis] = useState(false);
  const [sawPhotosOnlineFlag, sawPhotosOnlineVis] = useState(false);
  const [needHelpWithDriveFlag, needHelpWithDriveVis] = useState(false);
  const [yesNeedHelp, yesNeedHelpVis] = useState(false);
  const [noNeedHelp, noNeedHelpVis] = useState(false);
  const [learnMoreRequestingFlag, learnMoreRequestingVis] = useState(false);
  const [yesToNeedHelpWAccessFlag, yesToNeedHelpWAccessVis] = useState(false);
  const [haveTalkedToFriendsFlag, haveTalkedToFriendsVis] = useState(false);
  const [noToHaveTalkedToFamilyFlag, noToHaveTalkedToFamilyVis] = useState(
    false
  );
  const [ipFlag, ipVis] = useState(false);
  const [ipRightsFlag, ipRightsVis] = useState(false);
  const [photoMonetizedFlag, photoMonetizedVis] = useState(false);
  const [noToMonetizedFlag, noToMonetizedVis] = useState(false);
  const [yesToMonetizedFlag, yesToMonetizedVis] = useState(false);
  const [tosStuffFlag, tosVis] = useState(false);
  const [continueBtn, continueVisibility] = useState(false);
  const [nextPage, nextPageValue] = useState("");

  const allPassedProps = props.location.state;
  const name = allPassedProps.p_name.name;
  const tasksToDelegate = allPassedProps.tasks.tasksToDelegate;
  const agendaEstateAttorney = allPassedProps.estateTasks.agendaEstateAttorney;
  const agendaIP = allPassedProps.IPTasks.agendaIP;
  const agendaIT = allPassedProps.ITTasks.agendaIT;
  const socialAccountsToDelete =
    allPassedProps.socialDeleteAccounts.socialAccountsToDelete;
  const emailAccountsToDelete =
    allPassedProps.emailDeleteAccounts.emailAccountsToDelete;
  const mediaTypeList = allPassedProps.mediaTypes.mediaTypeList;
  // const emailConnectedTo = [];
  const tosList = [];

  function nextPageHelper() {
    if (nextPage.length > 0) {
      return nextPage;
    }
    if (mediaTypeList.length > 0) {
      var page = mediaTypeList.pop();
      switch (page) {
        case "Videos":
          nextPageValue("/VideoQuestions");
          break;
        case "Music":
          nextPageValue("/MusicQuestions");
          break;
        case "Social Media":
          nextPageValue("/SocialQuestions");
          break;
        case "Email":
          nextPageValue("/EmailQuestions");
          break;
        default:
          break;
      }
    } else {
      nextPageValue("./EndPage");
    }
  }

  function gatherData() {
    var yesNeedHelpString =
      "Inquire about service availability and timing for searching for image files on hard drives, discs, flash drives, SD cards, and or cameras";

    var noNeedHelpString =
      "Look for photos on discs, flash drives, SD cards, hard drives and/or cameras";

    var yesToNeedHelpWAccess =
      "Discuss process and possibility of accessing deceased loved one's photos from an online platform";

    var noToHaveTalkedToFamilyString =
      "Talk to friends or family about locating copies of photos";

    var yesToMonetizedString =
      "Talk to an Intellectual Property Attorney about rights in income-producing photos I took";

    var noToMonetizedString =
      "Talk to an Intellectual Property Attorney about rights in photos I took";

    if (yesNeedHelp === true && !agendaIT.includes(yesNeedHelpString)) {
      agendaIT.push(yesNeedHelpString);
    }
    if (noNeedHelp === true && !tasksToDelegate.includes(noNeedHelpString)) {
      tasksToDelegate.push(noNeedHelpString);
    }

    if (
      yesToNeedHelpWAccessFlag === true &&
      !agendaEstateAttorney.includes(yesToNeedHelpWAccess)
    ) {
      agendaEstateAttorney.push(yesToNeedHelpWAccess);
    }

    if (
      noToHaveTalkedToFamilyFlag === true &&
      !tasksToDelegate.includes(noToHaveTalkedToFamilyString)
    ) {
      tasksToDelegate.push(noToHaveTalkedToFamilyString);
    }

    if (
      yesToMonetizedFlag === true &&
      !agendaIP.includes(yesToMonetizedString)
    ) {
      agendaIP.push(yesToMonetizedString);
    }

    if (noToMonetizedFlag === true && !agendaIP.includes(noToMonetizedString)) {
      agendaIP.push(noToMonetizedString);
    }
  }

  function initialPhotoQuestionHelper(answer) {
    nextPageHelper();
    if (answer === "Internet/Website") {
      digiFormatEduVis(false);
      websiteFormatVis(true);
      haveLookedDriveVis(true);
    } else if (answer === "Select") {
      digiFormatEduVis(false);
      websiteFormatVis(false);
      haveLookedDriveVis(false);
    } else {
      digiFormatEduVis(true);
      websiteFormatVis(false);
      haveLookedDriveVis(true);
    }
    visHelper(1);
  }

  function hardriveQuestionHelper(answer) {
    if (answer === "Yes") {
      if (websiteFormatEduFlag === false) {
        learnMoreRequestingVis(false);
        needHelpWithDriveVis(false);
        sawPhotosOnlineVis(true);
      } else {
        learnMoreRequestingVis(true);
        needHelpWithDriveVis(false);
        sawPhotosOnlineVis(false);
      }
    } else if (answer === "No") {
      learnMoreRequestingVis(false);
      needHelpWithDriveVis(true);
      sawPhotosOnlineVis(false);
    } else {
      learnMoreRequestingVis(false);
      needHelpWithDriveVis(false);
      sawPhotosOnlineVis(false);
    }
    visHelper(2);
  }

  function sawPhotosOnlineHelper(answer) {
    if (answer === "Yes") {
      learnMoreRequestingVis(true);
      haveTalkedToFriendsVis(false);
    } else if (answer === "No") {
      learnMoreRequestingVis(false);
      haveTalkedToFriendsVis(true);
    } else {
      learnMoreRequestingVis(false);
      haveTalkedToFriendsVis(false);
    }
    visHelper(3);
  }

  function learnMoreRequestingAccessHelper(answer) {
    if (answer === "Yes") {
      yesToNeedHelpWAccessVis(true);
      haveTalkedToFriendsVis(true);
    } else if (answer === "No") {
      yesToNeedHelpWAccessVis(false);
      haveTalkedToFriendsVis(true);
    } else {
      yesToNeedHelpWAccessVis(false);
      haveTalkedToFriendsVis(false);
    }
    visHelper(3);
  }
  function needHelpHelper(answer) {
    if (answer === "Yes") {
      yesNeedHelpVis(true);
      noNeedHelpVis(false);
      haveTalkedToFriendsVis(true);
    } else if (answer === "No") {
      yesNeedHelpVis(false);
      noNeedHelpVis(true);
      haveTalkedToFriendsVis(true);
    } else {
      yesNeedHelpVis(false);
      noNeedHelpVis(false);
      haveTalkedToFriendsVis(false);
    }
    visHelper(4);
  }

  function famFriendsHelper(answer) {
    if (answer === "Yes") {
      ipVis(true);
      noToHaveTalkedToFamilyVis(false);
    } else if (answer === "No") {
      noToHaveTalkedToFamilyVis(true);
      ipVis(true);
    } else {
      noToHaveTalkedToFamilyVis(false);
      ipVis(false);
    }
    visHelper(5);
  }

  function ipQuestionHelper(answer) {
    if (answer === "Yes") {
      // tosVis(false);
      continueVisibility(false);
      ipRightsVis(true);
    } else if (answer === "No") {
      // tosVis(true);
      continueVisibility(true);
      ipRightsVis(false);
    } else {
      ipRightsVis(false);
      // tosVis(false);
      continueVisibility(false);
    }
    visHelper(6);
  }

  function doYouWantLearnIPRightsHelper(answer) {
    if (answer === "Yes") {
      photoMonetizedVis(true);
      // tosVis(false);
      continueButton(false);
    } else if (answer === "No") {
      photoMonetizedVis(false);
      // tosVis(true);
      continueVisibility(true);
      visHelper(7);
    } else {
      photoMonetizedVis(false);
      // tosVis(false);
      continueVisibility(false);
      visHelper(7);
    }
  }

  function monetizeHelper(answer) {
    if (answer === "Yes") {
      yesToMonetizedVis(true);
      noToMonetizedVis(false);
      // tosVis(true);
      continueVisibility(true);
    } else if (answer === "No") {
      yesToMonetizedVis(false);
      noToMonetizedVis(true);
      // tosVis(true);
      continueVisibility(true);
    } else {
      yesToMonetizedVis(false);
      noToMonetizedVis(false);
      // tosVis(false);
      continueVisibility(true);
    }
  }

  function visHelper(level) {
    switch (level) {
      case 1:
        needHelpWithDriveVis(false);
        sawPhotosOnlineVis(false);
        learnMoreRequestingVis(false);
        yesToNeedHelpWAccessVis(false);
        yesNeedHelpVis(false);
        noNeedHelpVis(false);
        haveTalkedToFriendsVis(false);
        ipVis(false);
        noToHaveTalkedToFamilyVis(false);
        ipRightsVis(false);
        yesToMonetizedVis(false);
        noToMonetizedVis(false);
        photoMonetizedVis(false);
        tosVis(false);
        continueVisibility(false);
        break;
      case 2:
        yesNeedHelpVis(false);
        noNeedHelpVis(false);
        yesToNeedHelpWAccessVis(false);
        haveTalkedToFriendsVis(false);
        ipVis(false);
        noToHaveTalkedToFamilyVis(false);
        ipRightsVis(false);
        photoMonetizedVis(false);
        yesToMonetizedVis(false);
        noToMonetizedVis(false);
        tosVis(false);
        continueVisibility(false);
        break;
      case 3:
        yesNeedHelpVis(false);
        noNeedHelpVis(false);
        ipVis(false);
        noToHaveTalkedToFamilyVis(false);
        ipRightsVis(false);
        photoMonetizedVis(false);
        yesToMonetizedVis(false);
        noToMonetizedVis(false);
        tosVis(false);
        continueVisibility(false);
        break;
      case 4:
        ipVis(false);
        noToHaveTalkedToFamilyVis(false);
        ipRightsVis(false);
        photoMonetizedVis(false);
        yesToMonetizedVis(false);
        noToMonetizedVis(false);
        tosVis(false);
        continueVisibility(false);
        break;
      case 5:
        ipRightsVis(false);
        photoMonetizedVis(false);
        yesToMonetizedVis(false);
        noToMonetizedVis(false);
        tosVis(false);
        continueVisibility(false);
        break;
      case 6:
        yesToMonetizedVis(false);
        noToMonetizedVis(false);
        photoMonetizedVis(false);
        break;
      case 7:
        yesToMonetizedVis(false);
        noToMonetizedVis(false);
        break;
      default:
        break;
    }
  }

  const headerJumbo = () => (
    <div>
      <div class="jumbotron jumbotron-fluid">
        <div class="container">
          <h1 class="display-4">Deciding What To Do with Photos</h1>
          <p class="lead">
            Photos are the digital asset we understand people are most concerned
            about accessing or preserving when a loved one dies. Answer the
            following questions to create your task list surrounding finding,
            accessing, and preserving digital photos
          </p>
        </div>
      </div>
    </div>
  );

  const lastTimePhotosSeenQComponent = () => (
    <QuestionSelect
      question={"Where was the last place you saw the photos?"}
      options={[
        "Select",
        " In a digital format not on the internet (e.g. a wedding reel, anniversary party)",
        "Internet/Website",
      ]}
      function={initialPhotoQuestionHelper}
    />
  );

  const digitalFormatEducationComponenet = () => (
    <TakeNoteMessage
      message="Your best bet is asking people who attended the event, or looking for
    files on a computer related to that event. For formal events and
    professional photos, it may be worth contacting the professional
    photographer from an event or photo shoot to ask if they have copies
    they can help you recover."
    />
  );

  const websitePhotoFormatEducationComponenet = () => (
    <TakeNoteMessage
      message="Even though you last saw the photos on a website, most photos are saved
    or backed up to other locations. If you do not have access through
    shared settings to the website you last saw the photo, it may be easier
    to look on harddrives, discs and flash drives before requesting an
    online service grant access to a deceased loved one's account."
    />
  );

  const haveLookedDrivesForPhotoComponent = () => (
    <QuestionSelect
      question={
        "Have you looked on harddrives, discs, flash drives, SD cards, or cameras for the photos?"
      }
      options={["Select", "Yes", "No"]}
      function={hardriveQuestionHelper}
    />
  );

  const sawPhotosOnlineComponent = () => (
    <QuestionSelect
      question={"Did you also see the photos online somewhere?"}
      options={["Select", "Yes", "No"]}
      function={sawPhotosOnlineHelper}
    />
  );

  const learnMoreRequestingAccessComponent = () => (
    <QuestionSelect
      question={
        "Do you want to learn more about contacting the online platform to request access to the photos?"
      }
      options={["Select", "Yes", "No"]}
      function={learnMoreRequestingAccessHelper}
    />
  );

  const yesToNeedHelpRequestingAccessComponent = () => (
    <TaskListMessage
      task={
        "Talk to Estate Attorney about accessing deceased loved one's photos from online platform"
      }
    />
  );

  const needHelpWithDriveQuestionComponent = () => (
    <QuestionSelect
      question={"Do you need help doing that?"}
      options={["Select", "Yes", "No"]}
      function={needHelpHelper}
    />
  );

  const yesToNeedHelpWithDrive = () => (
    <TaskListMessage
      task={
        "Talk to IT Professional about searching for photos on harddrive and cameras"
      }
    />
  );

  const noToNeedHelpWithDrive = () => (
    <TaskListMessage task={"Look for photos on harddrive or camera"} />
  );

  const haveYouTalkedToFriendsCopiesQuestionComponent = () => (
    <QuestionSelect
      question={
        "Have you talked to friends or family about locating copies of the photos you have in mind?"
      }
      options={["Select", "Yes", "No"]}
      function={famFriendsHelper}
    />
  );

  const noToHaveTalkedToFamily = () => (
    <TaskListMessage
      task={"Talk to friends or family about locating copies of photos"}
    />
  );

  const ipQuestion = () => (
    <QuestionSelect
      question={"Did you take the photos you are looking for?"}
      options={["Select", "Yes", "No"]}
      function={ipQuestionHelper}
    />
  );

  const doYouWantLearnIPRights = () => (
    <QuestionSelect
      question={
        "Do you want to learn about your Intellectual Property rights in the photos?"
      }
      options={["Select", "Yes", "No"]}
      function={doYouWantLearnIPRightsHelper}
    />
  );

  const photoMonetizedQuestionComponent = () => (
    <QuestionSelect
      question={"Are the photos online monetized?"}
      options={["Select", "Yes", "No"]}
      function={monetizeHelper}
    />
  );

  const yesToMenetized = () => (
    <TaskListMessage
      task={
        "Talk to an Intellectual Property Attorney about rights in income-producing photos I took"
      }
    />
  );

  const noToMenetized = () => (
    <TaskListMessage
      task={
        "Talk to an Intellectual Property Attorney about rights in photos I took"
      }
    />
  );

  const tosComponent = () => (
    <div class="form-group">
      <br />
      <br />
      <h5>
        Click to learn more about specific photo platforms and select down below
        what infromation you would like to be added to your Task List
      </h5>
      <SimpleAccordion type="photo" />
      <Select
        selection="photo"
        description="Select photo services to be added to your Task List"
        accountsList={tosList}
      />
    </div>
  );

  const continueButton = () => (
    <div class="form-group">
      <NavLink
        to={{
          pathname: nextPage,
          state: {
            p_name: { name },
            tasks: { tasksToDelegate },
            estateTasks: { agendaEstateAttorney },
            IPTasks: { agendaIP },
            ITTasks: { agendaIT },
            socialDeleteAccounts: { socialAccountsToDelete },
            emailDeleteAccounts: { emailAccountsToDelete },
            mediaTypes: { mediaTypeList },
          },
        }}
      >
        <button
          type="button"
          class="btn btn-dark btn-lg"
          onClick={() => gatherData()}
        >
          Continue
        </button>
      </NavLink>
    </div>
  );

  return (
    <div>
      {headerJumbo()}
      <div class="col-md-9">
        {lastTimePhotosSeenQComponent()}
        {digiFormatEduFlag ? digitalFormatEducationComponenet() : ""}
        {websiteFormatEduFlag ? websitePhotoFormatEducationComponenet() : ""}
        {haveLookedDriveFlag ? haveLookedDrivesForPhotoComponent() : ""}
        {sawPhotosOnlineFlag ? sawPhotosOnlineComponent() : ""}
        {needHelpWithDriveFlag ? needHelpWithDriveQuestionComponent() : ""}
        {yesNeedHelp ? yesToNeedHelpWithDrive() : ""}
        {noNeedHelp ? noToNeedHelpWithDrive() : ""}
        {learnMoreRequestingFlag ? learnMoreRequestingAccessComponent() : ""}
        {yesToNeedHelpWAccessFlag
          ? yesToNeedHelpRequestingAccessComponent()
          : ""}
        {haveTalkedToFriendsFlag
          ? haveYouTalkedToFriendsCopiesQuestionComponent()
          : ""}
        {noToHaveTalkedToFamilyFlag ? noToHaveTalkedToFamily() : ""}
        {ipFlag ? ipQuestion() : ""}
        {ipRightsFlag ? doYouWantLearnIPRights() : ""}
        {photoMonetizedFlag ? photoMonetizedQuestionComponent() : ""}
        {noToMonetizedFlag ? noToMenetized() : ""}
        {yesToMonetizedFlag ? yesToMenetized() : ""}
        {tosStuffFlag ? tosComponent() : ""}
      </div>
      <div class="text-center">{continueBtn ? continueButton() : ""}</div>
    </div>
  );
};
