import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import QuestionSelect from "./Components/QuestionSelect";
import TaskListMessage from "./Components/TaskListMessage";
import TakeNoteMessage from "./Components/TakeNoteMessage";

export const VidoeQuestions = (props) => {
  const [digiFormatEduFlag, digiFormatEduVis] = useState(false);
  const [websiteFormatEduFlag, websiteFormatVis] = useState(false);
  const [haveLookedDriveFlag, haveLookedDriveVis] = useState(false);
  const [sawPhotosOnlineFlag, sawPhotosOnlineVis] = useState(false);
  const [needHelpWithDriveFlag, needHelpWithDriveVis] = useState(false);
  const [yesNeedHelp, yesNeedHelpVis] = useState(false);
  const [noNeedHelp, noNeedHelpVis] = useState(false);
  const [learnMoreRequestingFlag, learnMoreRequestingVis] = useState(false);
  const [yesToNeedHelpWAccessFlag, yesToNeedHelpWAccessVis] = useState(false);
  const [haveTalkedToFriendsFlag, haveTalkedToFriendsVis] = useState(false);
  const [haveTalkedToFriendsFlag2, haveTalkedToFriendsVis2] = useState(false);
  const [noToHaveTalkedToFamilyFlag, noToHaveTalkedToFamilyVis] = useState(
    false
  );
  const [ipFlag, ipVis] = useState(false);
  const [ipRightsFlag, ipRightsVis] = useState(false);
  const [photoMonetizedFlag, photoMonetizedVis] = useState(false);
  const [noToMonetizedFlag, noToMonetizedVis] = useState(false);
  const [yesToMonetizedFlag, yesToMonetizedVis] = useState(false);
  const [continueBtn, continueVisibility] = useState(false);
  const [nextPage, nextPageValue] = useState("");

  const allPassedProps = props.location.state;
  const name = allPassedProps.p_name.name;
  const tasksToDelegate = allPassedProps.tasks.tasksToDelegate;
  const agendaEstateAttorney = allPassedProps.estateTasks.agendaEstateAttorney;
  const agendaIP = allPassedProps.IPTasks.agendaIP;
  const agendaIT = allPassedProps.ITTasks.agendaIT;
  const socialAccountsToDelete =
    allPassedProps.socialDeleteAccounts.socialAccountsToDelete;
  const emailAccountsToDelete =
    allPassedProps.emailDeleteAccounts.emailAccountsToDelete;
  const mediaTypeList = allPassedProps.mediaTypes.mediaTypeList;
  const emailConnectedTo = [];
  const tosList = [];

  function nextPageHelper() {
    if (nextPage.length > 0) {
      return nextPage;
    }
    if (mediaTypeList.length > 0) {
      var page = mediaTypeList.pop();
      switch (page) {
        case "Photos":
          nextPageValue("/PhotoQuestions");
          break;
        case "Music":
          nextPageValue("/MusicQuestions");
          break;
        case "Social Media":
          nextPageValue("/SocialQuestions");
          break;
        case "Email":
          nextPageValue("/EmailQuestions");
          break;
        default:
          break;
      }
    } else {
      nextPageValue("./EndPage");
    }
  }

  function gatherData() {
    var yesNeedHelpString =
      "Inquire about service availability and timing for searching for video files on hard drives, discs, flash drives, SD cards, and or cameras";

    var noNeedHelpString =
      "Look for videos on hard drives, discs, flash drives, or cameras";

    var yesToNeedHelpWAccess =
      "Discuss process and possibility of accessing deceased loved one's videos from an online platform";

    var noToHaveTalkedToFamilyString =
      "Talk to collaborators about locating or accessing videos";

    var yesToMonetizedString =
      "Review my rights in income-producing videos I helped create";

    var noToMonetizedString = "Discuss my rights in videos I helped create";

    if (yesNeedHelp === true && !agendaIT.includes(yesNeedHelpString)) {
      agendaIT.push(yesNeedHelpString);
    }
    if (noNeedHelp === true && !tasksToDelegate.includes(noNeedHelpString)) {
      tasksToDelegate.push(noNeedHelpString);
    }

    if (
      yesToNeedHelpWAccessFlag === true &&
      !agendaEstateAttorney.includes(yesToNeedHelpWAccess)
    ) {
      agendaEstateAttorney.push(yesToNeedHelpWAccess);
    }

    if (
      noToHaveTalkedToFamilyFlag === true &&
      !tasksToDelegate.includes(noToHaveTalkedToFamilyString)
    ) {
      tasksToDelegate.push(noToHaveTalkedToFamilyString);
    }

    if (
      yesToMonetizedFlag === true &&
      !agendaIP.includes(yesToMonetizedString)
    ) {
      agendaIP.push(yesToMonetizedString);
    }

    if (noToMonetizedFlag === true && !agendaIP.includes(noToMonetizedString)) {
      agendaIP.push(noToMonetizedString);
    }
  }
  function initialPhotoQuestionHelper(answer) {
    nextPageHelper();
    if (answer === "Internet/Website") {
      digiFormatEduVis(false);
      websiteFormatVis(true);
      haveLookedDriveVis(true);
    } else if (answer === "Select") {
      digiFormatEduVis(false);
      websiteFormatVis(false);
      haveLookedDriveVis(false);
    } else {
      digiFormatEduVis(true);
      websiteFormatVis(false);
      haveLookedDriveVis(true);
    }
    visHelper(1);
  }

  function hardriveQuestionHelper(answer) {
    if (answer === "Yes") {
      if (websiteFormatEduFlag === false) {
        learnMoreRequestingVis(false);
        needHelpWithDriveVis(false);
        sawPhotosOnlineVis(true);
      } else {
        learnMoreRequestingVis(true);
        needHelpWithDriveVis(false);
        sawPhotosOnlineVis(false);
      }
    } else if (answer === "Select") {
      learnMoreRequestingVis(false);
      needHelpWithDriveVis(false);
      sawPhotosOnlineVis(false);
    } else {
      needHelpWithDriveVis(true);
      learnMoreRequestingVis(false);
      sawPhotosOnlineVis(false);
    }
    visHelper(2);
  }

  function sawPhotosOnlineHelper(answer) {
    if (answer === "Yes") {
      learnMoreRequestingVis(true);
      haveTalkedToFriendsVis(false);
    } else if (answer === "No") {
      learnMoreRequestingVis(false);
      haveTalkedToFriendsVis(true);
    } else {
      learnMoreRequestingVis(false);
      haveTalkedToFriendsVis(false);
    }
    visHelper(3);
  }

  function learnMoreRequestingAccessHelper(answer) {
    if (answer === "Yes") {
      yesToNeedHelpWAccessVis(true);
      haveTalkedToFriendsVis(true);
    } else if (answer === "No") {
      yesToNeedHelpWAccessVis(false);
      haveTalkedToFriendsVis(true);
    } else {
      yesToNeedHelpWAccessVis(false);
      haveTalkedToFriendsVis(false);
    }
    visHelper(3);
  }

  function needHelpHelper(answer) {
    if (answer === "Yes") {
      yesNeedHelpVis(true);
      noNeedHelpVis(false);
      haveTalkedToFriendsVis(true);
    } else if (answer === "Select") {
      yesNeedHelpVis(false);
      noNeedHelpVis(false);
      haveTalkedToFriendsVis(false);
    } else {
      yesNeedHelpVis(false);
      noNeedHelpVis(true);
      haveTalkedToFriendsVis(true);
    }
    visHelper(4);
  }

  function famFriendsHelper(answer) {
    if (answer === "Yes") {
      haveTalkedToFriendsVis2(true);
      ipVis(false);
    } else if (answer === "Select") {
      ipVis(false);
      haveTalkedToFriendsVis2(false);
    } else {
      ipVis(true);
      haveTalkedToFriendsVis2(false);
    }
    visHelper(5);
  }

  function famFriendsHelper2(answer) {
    if (answer === "Yes") {
      ipVis(true);
      noToHaveTalkedToFamilyVis(false);
    } else if (answer === "Select") {
      noToHaveTalkedToFamilyVis(false);
      ipVis(false);
    } else {
      noToHaveTalkedToFamilyVis(true);
      ipVis(true);
    }
    visHelper(5);
  }

  function doYouWantLearnIPRightsHelper(answer) {
    if (answer === "Yes") {
      photoMonetizedVis(true);
      continueButton(false);
    } else if (answer === "No") {
      photoMonetizedVis(false);
      continueVisibility(true);
      visHelper(7);
    } else {
      photoMonetizedVis(false);
      continueVisibility(false);
      visHelper(7);
    }
  }

  function ipQuestionHelper(answer) {
    if (answer === "Yes") {
      ipRightsVis(true);
      continueVisibility(false);
    } else if (answer === "Select") {
      ipRightsVis(false);
      continueVisibility(false);
    } else {
      ipRightsVis(false);
      continueVisibility(true);
    }
    visHelper(6);
  }

  function monetizeHelper(answer) {
    if (answer === "Yes") {
      yesToMonetizedVis(true);
      noToMonetizedVis(false);
      continueVisibility(true);
    } else if (answer === "Select") {
      yesToMonetizedVis(false);
      noToMonetizedVis(false);
      continueVisibility(false);
    } else {
      yesToMonetizedVis(false);
      noToMonetizedVis(true);
      continueVisibility(true);
    }
  }

  function visHelper(level) {
    switch (level) {
      case 1:
        needHelpWithDriveVis(false);
        sawPhotosOnlineVis(false);
        learnMoreRequestingVis(false);
        yesToNeedHelpWAccessVis(false);
        yesNeedHelpVis(false);
        noNeedHelpVis(false);
        haveTalkedToFriendsVis(false);
        haveTalkedToFriendsVis2(false);
        ipVis(false);
        noToHaveTalkedToFamilyVis(false);
        ipRightsVis(false);
        yesToMonetizedVis(false);
        noToMonetizedVis(false);
        photoMonetizedVis(false);
        // tosVis(false);
        continueVisibility(false);
        break;
      case 2:
        yesNeedHelpVis(false);
        noNeedHelpVis(false);
        yesToNeedHelpWAccessVis(false);
        haveTalkedToFriendsVis(false);
        haveTalkedToFriendsVis2(false);
        ipVis(false);
        noToHaveTalkedToFamilyVis(false);
        ipRightsVis(false);
        photoMonetizedVis(false);
        yesToMonetizedVis(false);
        noToMonetizedVis(false);
        // tosVis(false);
        continueVisibility(false);
        break;
      case 3:
        yesNeedHelpVis(false);
        noNeedHelpVis(false);
        haveTalkedToFriendsVis2(false);
        ipVis(false);
        noToHaveTalkedToFamilyVis(false);
        ipRightsVis(false);
        photoMonetizedVis(false);
        yesToMonetizedVis(false);
        noToMonetizedVis(false);
        // tosVis(false);
        continueVisibility(false);
        break;
      case 4:
        ipVis(false);
        haveTalkedToFriendsVis2(false);
        noToHaveTalkedToFamilyVis(false);
        ipRightsVis(false);
        photoMonetizedVis(false);
        yesToMonetizedVis(false);
        noToMonetizedVis(false);
        // tosVis(false);
        continueVisibility(false);
        break;
      case 5:
        ipRightsVis(false);
        photoMonetizedVis(false);
        yesToMonetizedVis(false);
        noToMonetizedVis(false);
        // tosVis(false);
        continueVisibility(false);
        break;
      case 6:
        yesToMonetizedVis(false);
        noToMonetizedVis(false);
        photoMonetizedVis(false);
        break;
      case 7:
        yesToMonetizedVis(false);
        noToMonetizedVis(false);
        break;
      default:
        break;
    }
  }

  const headerJumbo = () => (
    <div>
      <div class="jumbotron jumbotron-fluid">
        <div class="container">
          <h1 class="display-4">Deciding What To Do With Videos</h1>
          <p class="lead">
            Many ways we access videos and movies online are through licenses.
            Some companies give us an opportunity to share our licenses and
            movie collections with friends and family, others don't. Almost no
            licenses to access recordings of hit movies are transferable on
            death. <br />
            <br />
            Answer the following questions about videos and movies you
            understand to be owned by your deceased loved one. (e.g recordings
            of a family event, or a film project for a class, or the
            professional work of a videographer, not their curated collection of
            hit movies)
          </p>
        </div>
      </div>
    </div>
  );

  const lastTimePhotosSeenQComponent = () => (
    <QuestionSelect
      question={"Where was the last place you saw the videos?"}
      options={[
        "Select",
        "In a digital format not on the internet (e.g. viewed as part of an in person gathering like a wedding or anniversary party)",
        "Internet/Website",
      ]}
      function={initialPhotoQuestionHelper}
    />
  );

  const digitalFormatEducationComponenet = () => (
    <TakeNoteMessage
      message="Your best bet is asking people at the event, or looking for files on a
    computer related to that event. For formal events and professional
    videos, it may be worth contacting the professional videographer at an
    event or video recording session to ask if they have copies they can
    help you recover."
    />
  );

  const websitePhotoFormatEducationComponenet = () => (
    <TakeNoteMessage
      message="Even though you saw the videos on a website, most videos are saved or
    backed up to other places/locations. If you do not have access through
    shared settings to the website you last saw the video, it is usually
    easier to look on hard drives, discs and flash drives before requesting
    access from an online service."
    />
  );

  const haveLookedDrivesForPhotoComponent = () => (
    <QuestionSelect
      question={
        "Have you looked on hard drives, discs, flash drives, or cameras for the videos?"
      }
      options={["Select", "Yes", "No"]}
      function={hardriveQuestionHelper}
    />
  );

  const sawPhotosOnlineComponent = () => (
    <QuestionSelect
      question={"Did you also see the video online somewhere?"}
      options={["Select", "Yes", "No"]}
      function={sawPhotosOnlineHelper}
    />
  );

  const learnMoreRequestingAccessComponent = () => (
    <QuestionSelect
      question={
        "Do you want to learn more about contacting the online platform to request access to the videos?"
      }
      options={["Select", "Yes", "No"]}
      function={learnMoreRequestingAccessHelper}
    />
  );

  const yesToNeedHelpRequestingAccessComponent = () => (
    <TaskListMessage
      task={
        "Talk to Estate Attorney about accessing deceased loved one's videos from online platform"
      }
    />
  );

  const needHelpWithDriveQuestionComponent = () => (
    <QuestionSelect
      question={"Do you need help doing that?"}
      options={["Select", "Yes", "No"]}
      function={needHelpHelper}
    />
  );

  const yesToNeedHelpWithDrive = () => (
    <TaskListMessage
      task={
        "Talk to IT Professional about searching for videos on hard drives, discs, flash drives, SD cards, or cameras"
      }
    />
  );

  const noToNeedHelpWithDrive = () => (
    <TaskListMessage
      task={
        "Look for videos on hard drives, discs, flash drives, SD cards, or cameras"
      }
    />
  );

  const haveYouTalkedToFriendsCopiesQuestionComponent = () => (
    <QuestionSelect
      question={
        "Did " +
        name +
        " have regular collaborators they worked with on their videos?"
      }
      options={["Select", "Yes", "No"]}
      function={famFriendsHelper}
    />
  );

  const haveYouTalkedToCollaboratorsCopiesQuestionComponent = () => (
    <QuestionSelect
      question={
        "Have you talked to " +
        name +
        "'s collaborators about locating video files or recordings you have in mind?"
      }
      options={["Select", "Yes", "No"]}
      function={famFriendsHelper2}
    />
  );

  const noToHaveTalkedToFamily = () => (
    <TaskListMessage
      task={"Talk to collaborators about locating or accessing videos"}
    />
  );

  const ipQuestion = () => (
    <QuestionSelect
      question={
        "Were you a collaborator or co-creator on the video you are looking for?"
      }
      options={["Select", "Yes", "No"]}
      function={ipQuestionHelper}
    />
  );

  const doYouWantLearnIPRights = () => (
    <QuestionSelect
      question={
        "Do you want to learn about your Intellectual Property rights in the video?"
      }
      options={["Select", "Yes", "No"]}
      function={doYouWantLearnIPRightsHelper}
    />
  );

  const photoMonetizedQuestionComponent = () => (
    <QuestionSelect
      question={"Are the videos online monetized?"}
      options={["Select", "Yes", "No"]}
      function={monetizeHelper}
    />
  );

  const yesToMenetized = () => (
    <TaskListMessage
      task={
        "Talk to an Intellectual Property Attorney about rights in income-producing videos I helped create"
      }
    />
  );

  const noToMenetized = () => (
    <TaskListMessage
      task={
        "Talk to an Intellectual Property Attorney about rights in videos I helped create"
      }
    />
  );

  const continueButton = () => (
    <div class="form-group">
      <NavLink
        to={{
          pathname: nextPage,
          state: {
            p_name: { name },
            tasks: { tasksToDelegate },
            estateTasks: { agendaEstateAttorney },
            IPTasks: { agendaIP },
            ITTasks: { agendaIT },
            socialDeleteAccounts: { socialAccountsToDelete },
            emailDeleteAccounts: { emailAccountsToDelete },
            mediaTypes: { mediaTypeList },
          },
        }}
      >
        <button
          type="button"
          class="btn btn-dark btn-lg"
          onClick={() => gatherData()}
        >
          Continue
        </button>
      </NavLink>
    </div>
  );

  return (
    <div>
      {headerJumbo()}
      <div class="col-md-9">
        {lastTimePhotosSeenQComponent()}
        {digiFormatEduFlag ? digitalFormatEducationComponenet() : ""}
        {websiteFormatEduFlag ? websitePhotoFormatEducationComponenet() : ""}
        {haveLookedDriveFlag ? haveLookedDrivesForPhotoComponent() : ""}
        {sawPhotosOnlineFlag ? sawPhotosOnlineComponent() : ""}
        {needHelpWithDriveFlag ? needHelpWithDriveQuestionComponent() : ""}
        {yesNeedHelp ? yesToNeedHelpWithDrive() : ""}
        {noNeedHelp ? noToNeedHelpWithDrive() : ""}
        {learnMoreRequestingFlag ? learnMoreRequestingAccessComponent() : ""}
        {yesToNeedHelpWAccessFlag
          ? yesToNeedHelpRequestingAccessComponent()
          : ""}
        {haveTalkedToFriendsFlag
          ? haveYouTalkedToFriendsCopiesQuestionComponent()
          : ""}
        {haveTalkedToFriendsFlag2
          ? haveYouTalkedToCollaboratorsCopiesQuestionComponent()
          : ""}
        {noToHaveTalkedToFamilyFlag ? noToHaveTalkedToFamily() : ""}
        {ipFlag ? ipQuestion() : ""}
        {ipRightsFlag ? doYouWantLearnIPRights() : ""}
        {photoMonetizedFlag ? photoMonetizedQuestionComponent() : ""}
        {noToMonetizedFlag ? noToMenetized() : ""}
        {yesToMonetizedFlag ? yesToMenetized() : ""}
      </div>
      <div class="text-center">{continueBtn ? continueButton() : ""}</div>
    </div>
  );
};
