import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { NavLink } from "react-router-dom";

export const NavigationBar = () => (
  <ul class="nav nav-pills nav-fill ">
    <li class="nav-item">
      <NavLink to="/">
        <a class="nav-link active bg-info">Home</a>
      </NavLink>
    </li>
    <li class="nav-item">
      <NavLink to="/Resources">
        <a class="nav-link text-info">Resources</a>
      </NavLink>
    </li>
    <li class="nav-item">
      <NavLink to="/About">
        <a class="nav-link text-info">About</a>
      </NavLink>
    </li>
  </ul>
);

export default NavigationBar;
