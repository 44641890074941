import React from "react";

export const About = () => {
  const Notices = () => (
    <div class="card form-group">
      <h5 class="card-header">Notices</h5>
      <div class="card-body">
        <p class="card-text">
          This website is maintained for educational purposes.This website is
          not legal advice. Before acting on any information you might find on
          this website, please contact an attorney licensed to practice in your
          jurisdiction and discuss your specific situation with them.
        </p>
      </div>
    </div>
  );

  const PrivacyPolicy = () => (
    <div class="card form-group">
      <h5 class="card-header">Privacy Policy</h5>
      <div class="card-body">
        <p class="card-text">
          Thank you for visiting us. This policy explains how The Law Office of
          Megan Yip, PC, collects and uses data through this website. If you
          have any questions, please ask. Please note, that by using our
          website, you agree to the terms of this policy. We reserve the right
          to change this policy from time to time. Any significant changes to
          this policy or to our website will be posted publicly.
          <br />
          <br />
          We do not automatically collect personally identifiable information,
          such as your name, email address, or other contact information.
        </p>
        <h5 class="card-title">
          Collection of Information When You Visit Our Website
        </h5>
        <p class="card-text">
          We do automatically collect certain other information to help us
          understand how readers use our site. This includes device data, which
          web browser you use, the type of operating system you use, the domain
          name of your internet service provider, or your IP address. This
          information is used internally for the sole purpose of evaluating and
          improving our website. We will keep your information confidential to
          the greatest extent allowed by law.
        </p>
      </div>
    </div>
  );
  return (
    <div>
      <div class="jumbotron jumbotron-fluid">
        <div class="container">
          <h3>
            NowWhat.Help is an educational site produced by the{" "}
            <a
              href="https://www.meganlyip.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <font color="17a2b8">Law Office of Megan Yip, PC.</font>
            </a>
          </h3>
          <br />
          <p class="lead">
            All inquiries and support requests to:{" "}
            <a href="#" target="_blank" rel="noopener noreferrer">
              <font color="17a2b8">support@NowWhat.Help</font>
            </a>
          </p>
        </div>
      </div>
      {Notices()}
    </div>
  );
};
