import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#17a2b8",
  },
}));

export default function SimpleAccordion(props) {
  const classes = useStyles();
  const values = selectionHelper(props.type);

  return (
    <div className={(classes.root, "form-group")}>
      {values.map((opns) => (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>{opns.heading}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{opns.body}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export function LawAccordion() {
  const classes = useStyles();

  return (
    <div className={(classes.root, "form-group")}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>
            Learn more about current laws limiting post death access to online
            account
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Technology companies may incur liability under the Electronic
            Communications Privacy Act (ECPA) if they release private
            communications to anyone other than the original account holder.{" "}
            <br /> <br />
            Under the Revised Uniform Fiduciary Access to Digital Assets Act
            (RUFADAA), an administrator or executor of the estate may be able to
            gain access to metadata in a deceased loved one's Email account
            (e.g. names of sender, subject lines etc.) in order to assist with
            the administration of the estate.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

function selectionHelper(passed_selection) {
  switch (passed_selection) {
    case "email":
      return email;
    case "photo":
      return photos;
    case "intestate succession":
      return intestateSuccession;
    case "laws":
      return lawEducation;
    default:
      break;
  }
}

const email = [
  {
    heading: "Yahoo",
    body:
      "You can only close the account. They will not give you access based on the agreement made with the account holder at the time of creation.",
  },
  {
    heading: "Google: Gmail",
    body:
      "They will work with immediate family members and representatives to close the account of a deceased person where appropriate. In certain circumstances they may provide content from a deceased user’s account. Google takes security and privacy seriously, they cannot provide passwords or other login details. Decisions will be made after a careful review.",
  },
  {
    heading: "Outlook",
    body: "Needs to be done",
  },
];

const photos = [
  {
    heading: "Facebook",
    body:
      "Facebook allows you to two options in an event of someone passing away. One option is to memorialize the account which is a way for people on Facebook to remember and celebrate those who've passed away. Another option is for the account to be deleted.",
  },
  {
    heading: "Instagram",
    body:
      "Instagram allows you to two options in an event of someone passing away. One option is to memorialize the account which is a way for people on Instagram to remember and celebrate those who've passed away. Another option is for the account to be deleted.",
  },
  {
    heading: "Apple iCloud",
    body:
      "Unless required by law will not transfer ownership to account or content. Upon receiving a death certificate, they will terminate your account and content.",
  },
  {
    heading: "Dropbox",
    body:
      "If you are able look on the loved one’s computer and look for the DropBox folder because it links with the online account. If you aren’t able to access their Dropbox folder on their computer, you’ll need to request access from Dropbox directly by providing documentation.",
  },
  {
    heading: "Google Drive/Photos",
    body:
      "They will work with immediate family members and representatives to close the account of a deceased person where appropriate. In certain circumstances they may provide content from a deceased user’s account. Google takes security and privacy seriously, they cannot provide passwords or other login details. Decisions will be made after a careful review.",
  },
  {
    heading: "Shutterfly",
    body: "They will allow access to accounts with a valid court order.",
  },
];

const intestateSuccession = [
  {
    heading: "Click Here for information on Intestate Succession",
    body:
      'Intestate Succession refers to the law of the State providing for the inheritance of property from a person who dies without leaving a will. Thus, to carry out an "intestate succession" simply means to transfer something after the owner has died and in accordance with the State law of intestate succession. Most transfers under intestate succession go to the family member who is deemed next of kin per the law.',
  },
];

const lawEducation = [
  {
    heading:
      "Click here for information on Electronic Communications Privacy Act (ECPA)",
    body:
      "Technology companies may incur liability under the Electronic Communications Privacy Act (ECPA) if they release private communications to anyone other than the original account holder.",
  },
  {
    heading:
      "Click here for information on Revised Uniform Fiduciary Access to Digital Assets Act (RUFADAA)",
    body:
      "Under the Revised Uniform F=iduciary Access to Digital Assets Act (RUFADAA), an administrator or executor of the estate may be able to gain access to metadata in a deceased loved one's Email account (e.g. names of sender, subject lines etc.) in order to assist with the administration of the estate.",
  },
];
