import React from "react";
import { NavLink } from "react-router-dom";
import { jsPDF } from "jspdf";

const EndPage = (props) => {
  const allPassedProps = props.location.state;
  const name = allPassedProps.p_name.name;
  const tasksToDelegate = allPassedProps.tasks.tasksToDelegate;
  const agendaEstateAttorney = allPassedProps.estateTasks.agendaEstateAttorney;
  const agendaIP = allPassedProps.IPTasks.agendaIP;
  const agendaIT = allPassedProps.ITTasks.agendaIT;
  const socialAccountsToDelete =
    allPassedProps.socialDeleteAccounts.socialAccountsToDelete;
  const emailAccountsToDelete =
    allPassedProps.emailDeleteAccounts.emailAccountsToDelete;
  const mediaTypeList = [];
  const doc = new jsPDF();

  function getLink(service_name) {
    switch (service_name) {
      case "Facebook":
        return "https://www.facebook.com/help/1506822589577997";
      case "Twitter":
        return "https://help.twitter.com/en/rules-and-policies/contact-twitter-about-media-on-a-deceased-family-members-account";
      case "LinkedIn":
        return "https://www.linkedin.com/help/linkedin/answer/2842/deceased-linkedin-member?lang=en";
      case "SnapChat":
        return "https://support.snapchat.com/en-US/i-need-help?start=5640758388326400";
      case "Instagram":
        return "https://help.instagram.com/264154560391256";
      case "Outlook":
        return "https://support.microsoft.com/en-us/office/accessing-outlook-com-onedrive-and-other-microsoft-services-when-someone-has-died-ebbd2860-917e-4b39-9913-212362da6b2f";
      case "Gmail":
        return "https://support.google.com/accounts/troubleshooter/6357590?hl=en";
      case "Apple iCloud":
        return "https://www.apple.com/ca/legal/internet-services/icloud/en/terms.html";
      case "Yahoo":
        return "https://help.yahoo.com/kb/SLN2021.html";
      default:
        return "";
    }
  }

  const pdf = () => {
    // Default export is a4 paper, portrait, using millimeters for units
    doc.setTextColor("#17a2b8");
    const nowWhatTitle = "Now What?";
    const xOffset = doc.internal.pageSize.width / 2;
    doc.textWithLink("https://nowwhat.help", xOffset, 285, {
      url: "https://nowwhat.help",
      align: "center",
    });
    // console.log(doc.getFontList());
    var img = new Image();
    // img.src = "./Assets/nowWhat.png";
    // doc.addImage(img.src, "PNG", xOffset, 10, {
    //   align: "center",
    // });
    doc.setFont("helvetica neue", "bold");
    doc.setFontSize(18);
    doc.text(nowWhatTitle, xOffset, 10, { align: "center" });
    doc.textWithLink(nowWhatTitle, xOffset, 10, {
      url: "https://nowwhat.help",
      align: "center",
    });
    doc.setTextColor("#000");

    const secondTitle = "Task List for Estate of " + name;
    doc.setFont("helvetica neue", "light");
    doc.setFontSize(16);
    doc.text(secondTitle, xOffset, 17, { align: "center" });
    doc.setFontSize(14);

    var lineIndex = 30;

    if (tasksToDelegate.length > 0) {
      const thirdTitle = "Tasks to Do or Delegate";
      doc.text(thirdTitle, 15, lineIndex, { align: "left" });
      lineIndex += 5;
      doc.setFontSize(12);
      var i;
      for (i = 0; i < tasksToDelegate.length; i++) {
        doc.text(" - " + tasksToDelegate[i], 15, lineIndex, {
          align: "left",
        });
        lineIndex += 5;
      }
      doc.setFontSize(14);
      lineIndex += 10;
    }

    if (agendaEstateAttorney.length > 0) {
      const fourthTitle = "Agenda for Meeting with Estate Attorney";
      doc.text(fourthTitle, 15, lineIndex, { align: "left" });
      lineIndex += 5;
      doc.setFontSize(12);
      var j;
      for (j = 0; j < agendaEstateAttorney.length; j++) {
        doc.text(" - " + agendaEstateAttorney[j], 15, lineIndex, {
          align: "left",
        });
        lineIndex += 5;
      }
      doc.setTextColor("#17a2b8");
      doc.textWithLink(
        "Click here to read about finding an Estate Attorney on our resources page",
        15,
        lineIndex,
        { url: "https://nowwhat.help/#/Resources" }
      );
      doc.setTextColor("#000");
      doc.setFontSize(14);
      lineIndex += 15;
    }

    if (agendaIT.length > 0) {
      const fifthTitle = "Agenda for Meeting with IT Professional";
      doc.text(fifthTitle, 15, lineIndex, { align: "left" });
      lineIndex += 5;
      doc.setFontSize(12);
      var k;
      for (k = 0; k < agendaIT.length; k++) {
        if (agendaIT[k].length > 79) {
          var first_part = agendaIT[k].substring(0, 79);
          var second_part = agendaIT[k].substring(79);
          doc.text(" - " + first_part, 15, lineIndex, {
            align: "left",
          });
          lineIndex += 5;
          doc.text("   " + second_part, 15, lineIndex, {
            align: "left",
          });
          lineIndex += 2;
        } else {
          doc.text(" - " + agendaIT[k], 15, lineIndex, {
            align: "left",
          });
        }
        lineIndex += 5;
      }
      doc.setTextColor("#17a2b8");
      doc.textWithLink(
        "Click here to read about finding an IT Professional on our resources page",
        15,
        lineIndex,
        { url: "https://nowwhat.help/#/Resources" }
      );
      doc.setTextColor("#000");
      doc.setFontSize(14);
      lineIndex += 10;
    }

    if (agendaIP.length > 0) {
      const sixthTitle =
        "Agenda for Meeting with Intellectual Property Attorney";
      doc.text(sixthTitle, 15, lineIndex, { align: "left" });
      lineIndex += 5;
      doc.setFontSize(12);
      var m;
      for (m = 0; m < agendaIP.length; m++) {
        doc.text(" - " + agendaIP[m], 15, lineIndex, {
          align: "left",
        });
        lineIndex += 5;
      }
      doc.setTextColor("#17a2b8");
      doc.textWithLink(
        "Click here to read about finding an Intellectual Property Attorney on our resources page",
        15,
        lineIndex,
        { url: "https://nowwhat.help/#/Resources" }
      );
      doc.setTextColor("#000");
      doc.setFontSize(14);
      lineIndex += 15;
    }
    var socialAccounts = [];
    if (socialAccountsToDelete.length > 0) {
      socialAccounts =
        socialAccountsToDelete[socialAccountsToDelete.length - 1];
    }
    if (socialAccounts.length > 0) {
      const title7 = "Social Media Accounts to Delete:";
      doc.text(title7, 15, lineIndex, { align: "left" });
      lineIndex += 5;
      doc.setFontSize(12);
      var n;
      for (n = 0; n < socialAccounts.length; n++) {
        doc.text(
          " - Delete " +
            socialAccounts[n] +
            " account. See policies and procedure at :",
          15,
          lineIndex,
          {
            align: "left",
          }
        );
        lineIndex += 5;
        doc.setTextColor("#17a2b8");
        doc.textWithLink(
          "   Visit " + socialAccounts[n] + " help page here",
          15,
          lineIndex,
          {
            url: getLink(socialAccounts[n]),
          }
        );
        lineIndex += 9;
        doc.setTextColor("#000");
      }
      doc.setFontSize(14);
      lineIndex += 6;
    }

    var emailAccounts = [];
    if (emailAccountsToDelete.length > 0) {
      emailAccounts = emailAccountsToDelete[emailAccountsToDelete.length - 1];
    }
    if (emailAccounts.length > 0) {
      var length = emailAccounts.length;
      var calculated_index = lineIndex + 5;
      if (length * 16 + calculated_index > 285) {
        doc.addPage();
        doc.setTextColor("#17a2b8");
        const nowWhatTitle = "Now What?";
        const xOffset = doc.internal.pageSize.width / 2;
        doc.setFontSize(18);
        doc.textWithLink("https://nowwhat.help", xOffset, 285, {
          url: "https://nowwhat.help",
          align: "center",
        });

        doc.setFont("helvetica neue", "bold");
        doc.text(nowWhatTitle, xOffset, 10, { align: "center" });
        doc.textWithLink(nowWhatTitle, xOffset, 10, {
          url: "https://nowwhat.help",
          align: "center",
        });
        doc.setTextColor("#000");

        const secondTitle = "Task List for Estate of " + name;
        doc.setFont("helvetica neue", "light");
        doc.setFontSize(16);
        doc.text(secondTitle, xOffset, 17, { align: "center" });
        doc.setFontSize(14);

        lineIndex = 30;
      }
      const title8 = "Email Media Accounts to Delete:";
      doc.text(title8, 15, lineIndex, { align: "left" });
      lineIndex += 5;
      doc.setFontSize(12);
      var p;
      for (p = 0; p < emailAccounts.length; p++) {
        doc.text(
          " - Delete " +
            emailAccounts[p] +
            " account. See policies and procedure at :",
          15,
          lineIndex,
          {
            align: "left",
          }
        );
        lineIndex += 5;
        doc.setTextColor("#17a2b8");
        doc.textWithLink(
          "   Visit " + emailAccounts[p] + " help page here",
          15,
          lineIndex,
          {
            url: getLink(emailAccounts[p]),
          }
        );
        lineIndex += 9;
        doc.setTextColor("#000");
      }
      doc.setFontSize(14);
      lineIndex += 6;
    }

    doc.output("dataurlnewwindow");
  };

  function getDoc() {
    doc.save(name + "TaskList.pdf");
  }

  pdf();

  return (
    <div class="jumbotron jumbotron-fluid">
      <div class="container">
        <h1 class="display-4">
          Finished. Thank you for taking the time to answer all of the questions
        </h1>
        <p class="lead">
          Your Task List was generated as a pdf file and should be opened in the
          next tab if your security settings permit.
        </p>
        <div className="text-center">
          <button type="button" class="btn btn-info btn-lg" onClick={getDoc}>
            Download Task List
          </button>
        </div>
      </div>
    </div>
  );
};
export default EndPage;
