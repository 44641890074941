import React from "react";
import Select from "./Components/Select";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

const MediaType = (props) => {
  const allPassedProps = props.location.state;
  const name = allPassedProps.p_name.name;
  const tasksToDelegate = allPassedProps.tasks.tasksToDelegate;
  const agendaEstateAttorney = allPassedProps.estateTasks.agendaEstateAttorney;
  const socialAccountsToDelete =
    allPassedProps.socialDeleteAccounts.socialAccountsToDelete;
  const emailAccountsToDelete =
    allPassedProps.emailDeleteAccounts.emailAccountsToDelete;
  const mediaTypeList = [];

  const headerJumbo = () => (
    <div>
      <div class="jumbotron jumbotron-fluid">
        <div class="container">
          <h1 class="display-4">Deciding What to do with Digital Assets</h1>
          <p class="lead">
            We have created the following interview questions based on the most
            popular digital assets people want to preserve or access after
            someone dies. Answer the following questions for all the digital
            assets you need a plan for.
          </p>
        </div>
      </div>
    </div>
  );

  const mediaTypeComponent = () => (
    <Select
      selection="media"
      description="Select which types of accounts or digital assets"
      accountsList={mediaTypeList}
    />
  );

  const continueButtonMediaType = () => (
    <div class="form-group">
      <NavLink
        to={{
          pathname: "/PreMediaQuestions",
          state: {
            p_name: { name },
            tasks: { tasksToDelegate },
            estateTasks: { agendaEstateAttorney },
            socialDeleteAccounts: { socialAccountsToDelete },
            emailDeleteAccounts: { emailAccountsToDelete },
            mediaTypes: { mediaTypeList },
          },
        }}
      >
        <button type="button" class="btn btn-info btn-lg">
          Continue
        </button>
      </NavLink>
    </div>
  );

  return (
    <div>
      {headerJumbo()}
      <div className="text-center">
        <h5>
          Select which types of accounts or digital assets you would like us to
          help you find or access
        </h5>
        <Grid container direction="row" justify="center" alignItems="center">
          {mediaTypeComponent()}
        </Grid>
        <br />

        {continueButtonMediaType()}
      </div>
    </div>
  );
};
export default MediaType;
