import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  HashRouter,
} from "react-router-dom";
import { Home } from "./Home";
import { About } from "./About";
import { Resources } from "./Resources";
import { NavigationBar } from "./Components/NavigationBar";
import PreliminaryQuestions from "./PreliminaryQuestions";
import MediaType from "./MediaType";
import DeleteList from "./DeleteList";
import { EmailQuestions } from "./EmailQuestions";
import { PhotoQuestions } from "./PhotoQuestions";
import { VidoeQuestions } from "./VideoQuestions";
import { MusicQuestions } from "./MusicQuestions";
import { SocialQuestions } from "./SocialQuestions";
import PreMediaQuestions from "./PreMediaQuestions";
import EndPage from "./EndPage";

function App() {
  return (
    <React.Fragment>
      <HashRouter>
        <NavigationBar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/Resources" component={Resources} />
          <Route path="/About" component={About} />
          <Route
            exact
            path="/PreliminaryQuestions"
            component={PreliminaryQuestions}
          />
          <Route exact path="/DeleteList" component={DeleteList} />
          <Route exact path="/MediaType" component={MediaType} />
          <Route
            exact
            path="/PreMediaQuestions"
            component={PreMediaQuestions}
          />
          <Route exact path="/EmailQuestions" component={EmailQuestions} />
          <Route exact path="/PhotoQuestions" component={PhotoQuestions} />
          <Route exact path="/VideoQuestions" component={VidoeQuestions} />
          <Route exact path="/MusicQuestions" component={MusicQuestions} />
          <Route exact path="/SocialQuestions" component={SocialQuestions} />
          <Route exact path="/EndPage" component={EndPage} />
        </Switch>
      </HashRouter>
    </React.Fragment>
  );
}
export default App;
