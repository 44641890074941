import React, { useState } from "react";
import Select from "./Components/Select";
import SimpleAccordion, { LawAccordion } from "./Components/Accordion";
import { NavLink } from "react-router-dom";
import QuestionSelect from "./Components/QuestionSelect";
import TaskListMessage from "./Components/TaskListMessage";
import TakeNoteMessage from "./Components/TakeNoteMessage";

export const EmailQuestions = (props) => {
  const [yesToHaveEmailInfoFlag, emailInfoVis] = useState(false);
  const [emailEducationFlag, emailEducationVis] = useState(false);
  const [workEmailQuestionFlag, workEmailVis] = useState(false);
  const [yesToWorkEmailFlag, yesToHaveWorkEmailVis] = useState(false);
  const [isTheEmailConnectedFlag, emailConnectedVis] = useState(false);
  const [connectedToWhatFlag, connectedToWhatVis] = useState(false);
  const [addToDeleteFlag, deleteVis] = useState(false);
  const [tosStuffFlag, tosVis] = useState(false);
  const [continueFlag, continueVis] = useState(false);
  const [nextPage, nextPageValue] = useState("");

  const allPassedProps = props.location.state;
  const name = allPassedProps.p_name.name;
  const tasksToDelegate = allPassedProps.tasks.tasksToDelegate;
  const agendaEstateAttorney = allPassedProps.estateTasks.agendaEstateAttorney;
  const agendaIP = allPassedProps.IPTasks.agendaIP;
  const agendaIT = allPassedProps.ITTasks.agendaIT;
  const socialAccountsToDelete =
    allPassedProps.socialDeleteAccounts.socialAccountsToDelete;
  const emailAccountsToDelete =
    allPassedProps.emailDeleteAccounts.emailAccountsToDelete;
  const mediaTypeList = allPassedProps.mediaTypes.mediaTypeList;
  const emailConnectedTo = [];
  const tosList = [];

  function nextPageHelper() {
    if (nextPage.length > 0) {
      return nextPage;
    }
    if (mediaTypeList.length > 0) {
      var page = mediaTypeList.pop();
      switch (page) {
        case "Videos":
          nextPageValue("/VideoQuestions");
          break;
        case "Music":
          nextPageValue("/MusicQuestions");
          break;
        case "Social Media":
          nextPageValue("/SocialQuestions");
          break;
        case "Photos":
          nextPageValue("/PhotoQuestions");
          break;
        default:
          break;
      }
    } else {
      nextPageValue("./EndPage");
    }
  }

  function gatherData() {
    var yesToHaveEmailInfoTaskString =
      "Review process and possibility of accessing email metadata that will help in administering the estate";
    var yesToWorkEmailTaskString =
      "Talk to employer or business partner about work-related email acccount";

    if (
      yesToHaveEmailInfoFlag === true &&
      !agendaEstateAttorney.includes(yesToHaveEmailInfoTaskString)
    ) {
      agendaEstateAttorney.push(yesToHaveEmailInfoTaskString);
    }

    if (
      yesToWorkEmailFlag === true &&
      !tasksToDelegate.includes(yesToWorkEmailTaskString)
    ) {
      tasksToDelegate.push(yesToWorkEmailTaskString);
    }
  }

  function initialEmailQuestionHelper(answer) {
    nextPageHelper();
    if (answer === "Yes") {
      emailInfoVis(true);
      emailEducationVis(true);
      workEmailVis(true);
    } else if (answer === "No") {
      emailInfoVis(false);
      emailEducationVis(true);
      workEmailVis(true);
    } else {
      emailInfoVis(false);
      emailEducationVis(false);
      workEmailVis(false);
    }
    visHelper(2);
  }

  function workEmailHelper(answer) {
    if (answer === "Yes") {
      yesToHaveWorkEmailVis(true);
      continueVis(true);
      // emailConnectedVis(true);
    } else if (answer === "No") {
      yesToHaveWorkEmailVis(false);
      continueVis(true);
      // emailConnectedVis(true);
    } else {
      yesToHaveWorkEmailVis(false);
      continueVis(false);
      // emailConnectedVis(false);
    }
    visHelper(3);
  }

  function connectedHelper(answer) {
    if (answer === "Yes") {
      connectedToWhatVis(true);
      deleteVis(false);
      // tosVis(true);
      continueVis(true);
    } else if (answer === "No") {
      connectedToWhatVis(false);
      deleteVis(true);
      // tosVis(false);
      continueVis(false);
    } else {
      connectedToWhatVis(false);
      deleteVis(false);
      // tosVis(false);
      continueVis(false);
    }
  }

  function emailAddedDeleteHelper(answer) {
    if (answer === "Yes") {
      // tosVis(true);
      continueVis(true);
    } else if (answer === "No") {
      // tosVis(true);
      continueVis(true);
    } else {
      // tosVis(false);
      continueVis(false);
    }
  }

  function visHelper(level) {
    switch (level) {
      case 2:
        yesToHaveWorkEmailVis(false);
        emailConnectedVis(false);
        connectedToWhatVis(false);
        deleteVis(false);
        tosVis(false);
        continueVis(false);
        break;
      case 3:
        connectedToWhatVis(false);
        deleteVis(false);
        tosVis(false);
        break;
      default:
        break;
    }
  }

  const welcomeMessage = () => (
    <div class="jumbotron jumbotron-fluid">
      <div class="container">
        <h1 class="display-4">Deciding What to Do with Email Accounts</h1>
        <p class="lead">
          Email Account for many people holds more information and more diverse
          information than any other online accounts.
          <br />
          <br />
          Answer the questions below and we will help you decide the next steps
          you should take related to Email Accounts.
        </p>
      </div>
    </div>
  );

  const emailHaveInfo = () => (
    <QuestionSelect
      question={
        "Are any of " +
        name +
        "’s email accounts likely to have information that will help in the administration of the estate?"
      }
      options={["Select", "Yes", "No"]}
      function={initialEmailQuestionHelper}
    />
  );

  const educationalPopupComponent = () => (
    <div>
      <TakeNoteMessage
        message="Consider talking to an attorney before you request deletion of email accounts that may contain information necessary to administer the estate. (e.g. bank account information, lists of assets, contacts etc.)
        Currently laws limit what you can request from email providers after someone dies; however if the account is deleted to soon, even less will be available."
      ></TakeNoteMessage>
      <LawAccordion />
    </div>
  );

  const yesToHaveEmailInfo = () => (
    <TaskListMessage
      task={
        "Talk to Estate Attorney about requesting access to email metadata that will help in administering the Estate"
      }
    />
  );

  const emailEducation = () => (
    <TakeNoteMessage message="Work - related email accounts and their contents are most likely the property of the business or employer" />
  );

  const workEmailQuestion = () => (
    <QuestionSelect
      question={
        "Do you know about a work-related email account or have questions about information you think is in a work-related email account?"
      }
      options={["Select", "Yes", "No"]}
      function={workEmailHelper}
    />
  );

  const yesToWorkEmailQuestion = () => (
    <TaskListMessage
      task={
        "Talk to employer or business partner about work-related email account"
      }
    />
  );

  const isTheEmailConnectedQuestion = () => (
    <QuestionSelect
      question={
        "Is there an email account that is attached to any other digital assets or online accounts that you would like to find or access?"
      }
      options={["Select", "Yes", "No"]}
      function={connectedHelper}
    />
  );

  const connectedToWhatQuestion = () => (
    <div class="form-group">
      <Select
        selection="all"
        description="To what accounts are they connected to"
        accountsList={emailConnectedTo}
      />
    </div>
  );

  const addToDeleteQuestion = () => (
    <QuestionSelect
      question={"Would you like this email to be added to delete list ?"}
      options={["Select", "Yes", "No"]}
      function={emailAddedDeleteHelper}
    />
  );

  const tosComponent = () => (
    <div class="form-group">
      <h5>
        Click to learn more about email platforms and their respective policies
        in order to select what you would like to add to your Task List
      </h5>
      <SimpleAccordion type="email" />
      <Select
        selection="email"
        description="Select email services"
        accountsList={tosList}
      />
    </div>
  );

  const continueButton = () => (
    <div class="form-group">
      <NavLink
        to={{
          pathname: nextPage,
          state: {
            p_name: { name },
            tasks: { tasksToDelegate },
            estateTasks: { agendaEstateAttorney },
            IPTasks: { agendaIP },
            ITTasks: { agendaIT },
            socialDeleteAccounts: { socialAccountsToDelete },
            emailDeleteAccounts: { emailAccountsToDelete },
            mediaTypes: { mediaTypeList },
          },
        }}
      >
        <button
          type="button"
          class="btn btn-dark btn-lg"
          onClick={() => gatherData()}
        >
          Continue
        </button>
      </NavLink>
    </div>
  );

  return (
    <div>
      {welcomeMessage()}
      <div class="col-md-9">
        {emailHaveInfo()}
        {yesToHaveEmailInfoFlag ? yesToHaveEmailInfo() : ""}
        {yesToHaveEmailInfoFlag ? educationalPopupComponent() : ""}
        {emailEducationFlag ? emailEducation() : ""}
        {workEmailQuestionFlag ? workEmailQuestion() : ""}
        {yesToWorkEmailFlag ? yesToWorkEmailQuestion() : ""}
        {isTheEmailConnectedFlag ? isTheEmailConnectedQuestion() : ""}
        {connectedToWhatFlag ? connectedToWhatQuestion() : ""}
        {addToDeleteFlag ? addToDeleteQuestion() : ""}
        {tosStuffFlag ? tosComponent() : ""}
        <div class="text-center">{continueFlag ? continueButton() : ""}</div>
      </div>
    </div>
  );
};
