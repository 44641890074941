/* eslint-disable no-use-before-define */
import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function Select(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <br />
      <Autocomplete
        multiple
        fullWidth={true}
        id="tags-outlined"
        options={selectionHelper(props.selection)}
        // getOptionLabel={(option) => option.title}
        onChange={(e, value) => props.accountsList.push(value)}
        // defaultValue={[monetizedAccounts[0]]}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={props.description}
            placeholder="Selected"
            fullWidth={true}
          />
        )}
      />
    </div>
  );
}

function selectionHelper(passed_selection) {
  switch (passed_selection) {
    case "social":
      return socialMedia;
    case "email":
      return emailServices;
    case "photo":
      return photoServices;
    case "media":
      return mediaTypes;
    case "all":
      return all;
    default:
      break;
  }
}

const socialMedia = [
  "Facebook",
  "Twitter",
  "LinkedIn",
  "SnapChat",
  "Instagram",
];

const emailServices = ["Gmail", "Outlook", "Apple iCloud", "Yahoo"];

const photoServices = [
  "Facebook",
  "Instagram",
  "Apple iCloud",
  "Dropbox",
  "Google Cloud/Photos",
  "Shutterfly",
  //   { title: "Amazon", year: 1972 },
  //   { title: "Microsoft", year: 1972 },
];

// const monetizedAccounts = ["YouTube", "Twitch", "Etsy", "AirBnb"];

const mediaTypes = ["Email", "Photos", "Videos", "Music", "Social Media"];

const all = [
  // { title: "Photos: Apple iCloud" },
  "Photos: Apple iCloud",
  "Dropbox",
  "Google Cloud/Photos",
  "Shutterfly",
  "Amazon Photos",
  "Microsoft",
  "Shutterfly",
  "YouTube",
  "Twitch",
  "Etsy",
  "AirBnb",
];
