import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { NavLink } from "react-router-dom";
import { Resources } from "./Resources";

export const Home = () => (
  <div>
    <div class="jumbotron jumbotron-fluid">
      <div class="container">
        <h1 class="display-4 center">
          <center>Now What ?</center>
        </h1>
        <p class="lead">
          <center>
            <h4>We help you decide what to do after someone dies</h4>
          </center>
          Grieving is hard. Taking Care of the administrative matters of a
          deceased loved one is complicated. <br /> <br />
          Answer the following set of questions and we will provide a
          personalized task list with suggested next steps to take in the estate
          administration process.
          <br />
          <br /> Note: Although we plan to expand, our service currently focuses
          on issues related to digital assets.
        </p>
      </div>
    </div>
    <NavLink to="/preliminaryQuestions">
      <div class="text-center">
        <button type="button" class="btn btn-info btn-lg">
          Get Started
        </button>
      </div>
    </NavLink>
  </div>
);
