import React from "react";
import Jumbo from "./Components/Jumbotron";

export const Resources = () => {
  const EstateAttorneyResources = () => (
    <div class="card form-group">
      <h5 class="card-header">Hiring An Estate Attorney</h5>
      <div class="card-body">
        <p class="card-text">
          Many Estate Attorneys practice both estate planning and post death
          administration. Others specialize in one side of things. <br />
          <br />
          If you received a task list from NowWhat.Help, you are looking for an
          Estate Attorney to answer questions about post death administration
          and possibly probate. <br />
          <br />
          As with all attorneys, it's important to find someone you feel
          comfortable talking to honestly and openly. <br />
          <br />
          If you’re in California, consider contacting the{" "}
          <a
            href="https://www.meganlyip.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <font color="17a2b8">Law Office of Megan Yip, PC</font>
          </a>
          . For other jurisdictions, you might want to start your search at{" "}
          <a
            href="https://www.estateplanning.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <font color="17a2b8">www.estateplanning.com</font>
          </a>
          . Alternatively, search business or professional directories for terms
          such as “post death administration lawyer” or “probate attorney” to
          find an attorney who may be able to help you. Many local Bar
          Associations also help you find attorney specialists in your area.
        </p>
      </div>
    </div>
  );

  const IT_Resources = () => (
    <div class="card form-group">
      <h5 class="card-header">Hiring an IT Professional</h5>
      <div class="card-body">
        <p class="card-text">
          There are three main kinds of IT resources you might need help from:
          <ol>
            <li>Computer repair shops</li>
            <li>Local IT Consultants</li>
            <li>IT Managed Service Providers (MSPs)</li>
          </ol>
          <br />
          Read below for more info on each, why you would choose them, and how
          to find them.
        </p>
        <h5 class="card-title">Computer Repair Shop</h5>
        <p class="card-text">
          If you have photos or videos on a computer that you need help finding
          or extracting from a crashed computer, then the best option would be a
          computer repair shop. They will often take the computer itself into
          their shop and do what they need to without you being there the whole
          time, and if the computer is crashed, they may even be able to repair
          it and get it running as a bonus.
          <br /> <br />
          To find one, we recommend doing a Google or Yelp search for “computer
          repair shop” and include either your city or zip code to find one
          nearby.
        </p>
        <h5 class="card-title">Local IT Consultant</h5>
        <p class="card-text">
          If you need more extensive IT assistance unrelated to your physical
          computer, like social media or email, and/or you really don’t want to
          bring your computer anywhere, you might work with a local IT
          Consultant. They help either in-person or remotely on one-time,
          short-term projects. However, because they are individuals, they are
          often not responsive when it comes to being an ongoing IT resource,
          and often take days if not weeks to get assistance from them when they
          get busy.
          <br /> <br />
          To find one, we recommend doing a Google or Yelp search for “local IT
          Consultant” and include either your city or zip code to find one
          nearby.
        </p>
        <h5 class="card-title">IT managed service provider (MSP)</h5>
        <p class="card-text">
          If you want an ongoing relationship with your chosen IT resource, this
          is the best option. MSPs specialize in providing ongoing support,
          consultations, and additional protection and training for things like
          backups, stronger security software, maintenance, and robust password
          management. Because they are a company with multiple staff, they are
          much more responsive when you need something later and will be
          available when you need them.
          <br /> <br />
          If you are a small business with fewer than 10 employees, or looking
          for help with your personal computers / general IT, then BH Tech
          Connection is a great resource for this type of ongoing IT support and
          service. You can find out more at{" "}
          <a
            href="https://bhtechconnection.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <font color="17a2b8">bhtechconnection.com</font>
          </a>
          .
        </p>
      </div>
    </div>
  );

  const IP_Resources = () => (
    <div class="card form-group">
      <h5 class="card-header">Hiring An Intellectual Property Attorney</h5>
      <div class="card-body">
        <p class="card-text">
          Intellectual property is a work or invention that is the result of
          creativity, such as a manuscript or a design, to which one has rights
          and for which one may apply for a patent, copyright, trademark, etc.
          <br />
          <br />
          If you are interested in your rights to something created digitally or
          created and stored digitally, you are going to be looking for an
          intellectual property attorney who advises on copyright.
          <br />
          <br />
          Many jurisdictions have Volunteer Lawyers for the Arts organizations
          that provide education and referral services to lawyers willing to
          help artists, or the family members of artists with copyright and
          intellectual property issues. You can find a directory of Lawyers for
          the Arts organizations here:{" "}
          <a
            href="https://vlany.org/national-directory-of-volunteer-lawyers-for-the-arts"
            target="_blank"
            rel="noopener noreferrer"
          >
            <font color="17a2b8">
              National Director of Volunteer Lawyers for the Arts Organizations
            </font>
          </a>
          . Alternatively, search business or professional directories for terms
          such as “copyright lawyer” or “intellectual property attorney” to find
          an attorney who may be able to help you. Many local Bar Associations
          also help you find attorney specialists in your area.
        </p>
      </div>
    </div>
  );

  return (
    <div>
      <div class="jumbotron jumbotron-fluid">
        <div class="container">
          <h1 class="display-4 center">Resources</h1>
        </div>
      </div>
      {EstateAttorneyResources()}
      {IT_Resources()}
      {IP_Resources()}
    </div>
  );
};
