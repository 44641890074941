import React, { useState } from "react";
import SimpleAccordion from "./Components/Accordion";
import QuestionSelect from "./Components/QuestionSelect";
import TaskListMessage from "./Components/TaskListMessage";
import { NavLink } from "react-router-dom";
import TakeNoteMessage from "./Components/TakeNoteMessage";
import { findAllInRenderedTree } from "react-dom/test-utils";

const PreliminaryQuestions = () => {
  const [name, nameValue] = useState('"Entered Name"');
  const [estatePlanFlag, estatePlanVis] = useState(false);
  const [planAnswered, setVis1] = useState(false);
  const [relationshipAnswered, relationshipVisibility] = useState(false);
  const [adminComponent, adminVisibility] = useState(false);
  const [docAnswerNo, docNoVisibility] = useState(false);
  const [adminQuestionYes, adminQuestionsYesVisibility] = useState(false);
  const [beneficiaryQ, beneficiaryVisibility] = useState(false);
  const [beneficiaryYes, beneficiaryYesVisibility] = useState(false);
  const [continueBtn, continueVisibility] = useState(false);
  const [estatePlanIDK, idkVisibility] = useState(false);
  const [nextOfKinFlag, nextOfKinVisibility] = useState(false);
  const [friendColleagueFlag, friendColleagueVis] = useState(false);
  const [notNextofKinFlag, notNextOfKinVisibility] = useState(false);
  const [intestateFlag, intestateVisibility] = useState(false);
  // const data = new Answers();
  const tasksToDelegate = [];
  const agendaEstateAttorney = [];

  function enteredNameHelper(p_name) {
    console.log(p_name);
    if (p_name === "Enter") {
      nameValue('"Entered Name"');
    } else {
      nameValue(p_name);
    }
    visHelper(1);
  }

  function estatePlanHelper(answer) {
    if (answer === "Yes") {
      setVis1(true);
      relationshipVisibility(false);
      idkVisibility(false);
      continueVisibility(false);
    } else if (answer === "No") {
      setVis1(false);
      relationshipVisibility(true);
      idkVisibility(false);
      continueVisibility(false);
    } else if (answer === "I don't know") {
      setVis1(false);
      relationshipVisibility(false);
      idkVisibility(true);
      continueVisibility(true);
    } else {
      setVis1(false);
      relationshipVisibility(false);
      idkVisibility(false);
      continueVisibility(false);
    }
    visHelper(2);
  }

  function relationshipHelper(response) {
    if (response === "Other family member") {
      friendColleagueVis(true);
      notNextOfKinVisibility(true);
      intestateVisibility(false);
      nextOfKinVisibility(false);
      continueVisibility(true);
    } else if (response === "Friend or Colleague") {
      friendColleagueVis(true);
      notNextOfKinVisibility(true);
      intestateVisibility(false);
      nextOfKinVisibility(false);
      continueVisibility(true);
    } else if (response === "Select") {
      friendColleagueVis(false);
      notNextOfKinVisibility(false);
      intestateVisibility(false);
      nextOfKinVisibility(false);
      continueVisibility(false);
    } else {
      friendColleagueVis(false);
      notNextOfKinVisibility(false);
      intestateVisibility(true);
      nextOfKinVisibility(true);
      continueVisibility(true);
    }
  }

  function docHelper(answer) {
    if (answer === "Yes") {
      adminVisibility(true);
      docNoVisibility(false);
      continueVisibility(false);
    } else if (answer === "No") {
      adminVisibility(false);
      docNoVisibility(true);
      continueVisibility(true);
    } else {
      adminVisibility(false);
      docNoVisibility(false);
      continueVisibility(false);
    }
    visHelper(3);
  }

  function adminHelper(answer) {
    if (answer === "Yes") {
      adminQuestionsYesVisibility(true);
      beneficiaryVisibility(false);
      continueVisibility(true);
    } else if (answer === "No") {
      adminQuestionsYesVisibility(false);
      beneficiaryVisibility(true);
      continueVisibility(false);
    } else {
      adminQuestionsYesVisibility(false);
      beneficiaryVisibility(false);
      continueVisibility(false);
    }
    visHelper(4);
  }

  function beneficiaryHelper(answer) {
    if (answer === "Yes") {
      beneficiaryYesVisibility(true);
      continueVisibility(true);
    } else if (answer === "No") {
      adminQuestionsYesVisibility(false);
      beneficiaryYesVisibility(false);
      continueVisibility(true);
    } else {
      adminQuestionsYesVisibility(false);
      beneficiaryYesVisibility(false);
      continueVisibility(false);
    }
  }

  function visHelper(level) {
    switch (level) {
      case 1:
        relationshipVisibility(false);
        setVis1(false);
        adminVisibility(false);
        docNoVisibility(false);
        adminQuestionsYesVisibility(false);
        beneficiaryVisibility(false);
        beneficiaryYesVisibility(false);
        friendColleagueVis(false);
        notNextOfKinVisibility(false);
        nextOfKinVisibility(false);
        intestateVisibility(false);
        beneficiaryYesVisibility(false);
        beneficiaryVisibility(false);
        adminQuestionsYesVisibility(false);
        continueVisibility(false);
        break;
      case 2:
        adminVisibility(false);
        docNoVisibility(false);
        intestateVisibility(false);
        adminQuestionsYesVisibility(false);
        beneficiaryVisibility(false);
        beneficiaryYesVisibility(false);
        friendColleagueVis(false);
        notNextOfKinVisibility(false);
        nextOfKinVisibility(false);
        break;
      case 3:
        beneficiaryYesVisibility(false);
        beneficiaryVisibility(false);
        adminQuestionsYesVisibility(false);
        break;
      case 4:
        beneficiaryYesVisibility(false);
        break;
      default:
        break;
    }
  }

  function gatherData() {
    if (docAnswerNo === true) {
      tasksToDelegate.push("Locate estate plan documents");
    }

    if (adminQuestionYes === true) {
      agendaEstateAttorney.push(
        "Review executor/adminstrator responsibilities"
      );
    }
    if (beneficiaryYes === true) {
      agendaEstateAttorney.push("Review rights as beneficiary");
    }
    if (estatePlanIDK === true) {
      tasksToDelegate.push(
        "Search for Estate Plan Documents. Dont forget to ask close friends and family"
      );
    }
    if (nextOfKinFlag === true) {
      agendaEstateAttorney.push(
        "Inquire about Intestate Succession applying to the estate"
      );
    }
    if (notNextofKinFlag === true) {
      tasksToDelegate.push("Get in touch with family administering estate.");
    }
  }

  const welcomeMessage = () => (
    <div class="jumbotron jumbotron-fluid">
      <div class="container">
        <h1 class="display-4">Getting Started</h1>
        <p class="lead">
          We designed our service to help you decide what to do after a certain
          person passes away. It will work best if you answer the following
          questions with a certain person and the particular digital assets they
          owned or used in mind.
          <br />
          <br /> Answer every question as accurately as possible and don't worry
          if you have to choose "I don't know", we will just add figuring the
          answer out to your task list, so you don't forget.
        </p>
      </div>
    </div>
  );

  const progressBar = () => (
    <div class="progress">
      <div
        class="progress-bar w-0"
        role="progressbar"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  );

  const progressBarCaption = () => (
    <div>
      <p>
        Progress Bar : <b>0%</b>
      </p>
    </div>
  );

  const enterName = () => (
    <div class="form-group">
      <label for="exampleInputEmail1">
        Enter your deceased loved one's name
      </label>
      <small id="emailHelp" class="form-text text-muted">
        This will help us personalize your experience
      </small>
      <input
        type="Text"
        class="form-control"
        id="Input1"
        aria-describedby="emailHelp"
        onInput={(e) => enteredNameHelper(e.currentTarget.value)}
        onKeyPress={(e) => estatePlanVis(true)}
      ></input>
    </div>
  );

  const estatePlan = () => (
    <QuestionSelect
      question={"Did " + name + " die with an estate plan (will or trust) ?"}
      options={["Select", "Yes", "No", "I don't know"]}
      function={estatePlanHelper}
    />
  );
  const relationship = () => (
    <QuestionSelect
      question={
        "What is your relationship to " +
        name +
        "? " +
        name +
        " is my _________ :"
      }
      options={[
        "Select",
        "Spouse",
        "Parent",
        "Child",
        "Sibling",
        "Other family member",
        "Friend or Colleague",
      ]}
      function={relationshipHelper}
    />
  );

  const continueButton = () => (
    <div class="form-group">
      <NavLink
        to={{
          pathname: "/deleteList",
          state: {
            p_name: { name },
            tasks: { tasksToDelegate },
            estateTasks: { agendaEstateAttorney },
          },
        }}
      >
        <button
          type="button"
          class="btn btn-dark btn-lg"
          onClick={gatherData()}
        >
          Continue
        </button>
      </NavLink>
    </div>
  );

  const haveDocs = () => (
    <QuestionSelect
      question={"Do you have the documents ?"}
      options={["Select", "Yes", "No"]}
      function={docHelper}
    />
  );

  const findDocs = () => (
    <TaskListMessage task={"Locate estate plan documents"} />
  );

  const adminQuestion = () => (
    <QuestionSelect
      question={"Are you named as an executor/administrator ?"}
      options={["Select", "Yes", "No"]}
      function={adminHelper}
    />
  );

  const yesToAdminQuestion = () => (
    <TaskListMessage
      task={
        "Talk to Estate Attorney about executor/administrator responsibilities"
      }
    />
  );

  const beneficiaryQuestion = () => (
    <QuestionSelect
      question={"Are you named as beneficiary ?"}
      options={["Select", "Yes", "No"]}
      function={beneficiaryHelper}
    />
  );

  const yesTobeneficiaryQuestion = () => (
    <TaskListMessage
      task={"Talk to Estate Attorney about your rights as a beneficiary"}
    />
  );

  const idkToEstatePlan = () => (
    <TaskListMessage
      task={
        "Search for Estate Plan Documents. Don’t forget to ask close friends and family if there was an estate plan"
      }
    />
  );

  const areNextOfKin = () => (
    <TaskListMessage
      task={"Talk to Estate Attorney about Intestate Succession"}
    />
  );

  const areNotNextOfKin = () => (
    <TaskListMessage task={"Get in touch with family administering estate"} />
  );

  const areFriendColleague = () => (
    <TakeNoteMessage
      message={
        "If there are no estate planning documents, then intestate succession laws usually give the most rights to the deceased person's next of kin, or closest family member. If you have an interest in or need access to the digital assets of a deceased friend or colleague, talking to their family is an important step in the process."
      }
    />
  );

  const intestateSuccession = () => (
    <div class="form-group">
      <SimpleAccordion type="intestate succession" />
    </div>
  );

  return (
    <div>
      <div class>{welcomeMessage()} </div>

      <form>
        <div class="col-md-9">
          <div>{enterName()} </div>
          {estatePlanFlag ? estatePlan() : ""}
          {planAnswered ? haveDocs() : ""}
          {relationshipAnswered ? relationship() : ""}
          {intestateFlag ? intestateSuccession() : ""}
          {nextOfKinFlag ? areNextOfKin() : ""}
          {friendColleagueFlag ? areFriendColleague() : ""}
          {notNextofKinFlag ? areNotNextOfKin() : ""}
          {estatePlanIDK ? idkToEstatePlan() : ""}
          {adminComponent ? adminQuestion() : ""}
          {docAnswerNo ? findDocs() : ""}
          {adminQuestionYes ? yesToAdminQuestion() : ""}
          {beneficiaryQ ? beneficiaryQuestion() : ""}
          {beneficiaryYes ? yesTobeneficiaryQuestion() : ""}
        </div>
        <div class="text-center">{continueBtn ? continueButton() : ""}</div>
      </form>
      {/* <div class="text-center col-md-12">
        {progressBarCaption()}
        {progressBar()}{" "}
      </div> */}
    </div>
  );
};

export default PreliminaryQuestions;
