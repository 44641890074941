import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import QuestionSelect from "./Components/QuestionSelect";
import TaskListMessage from "./Components/TaskListMessage";

export const SocialQuestions = (props) => {
  const [yesIsBusinessFlag, yesIsBusinessVis] = useState(false);
  const [isMonetizedQFlag, isMonetizedQVis] = useState(false);
  const [yesIsMonetizedFlag, yesIsMonetizedVis] = useState(false);
  const [areContactsQFlag, areContactsQVis] = useState(false);
  const [yesAreContactsFlag, yesAreContactsVis] = useState(false);
  const [IPQuestionFlag, IPQuestionVis] = useState(false);
  const [yesIsIPFlag, yesIsIPVis] = useState(false);
  const [continueBtn, continueVisibility] = useState(false);
  const [nextPage, nextPageValue] = useState("");

  const allPassedProps = props.location.state;
  const name = allPassedProps.p_name.name;
  const tasksToDelegate = allPassedProps.tasks.tasksToDelegate;
  const agendaEstateAttorney = allPassedProps.estateTasks.agendaEstateAttorney;
  const agendaIP = allPassedProps.IPTasks.agendaIP;
  const agendaIT = allPassedProps.ITTasks.agendaIT;
  const socialAccountsToDelete =
    allPassedProps.socialDeleteAccounts.socialAccountsToDelete;
  const emailAccountsToDelete =
    allPassedProps.emailDeleteAccounts.emailAccountsToDelete;
  const mediaTypeList = allPassedProps.mediaTypes.mediaTypeList;
  const emailConnectedTo = [];
  const tosList = [];

  function nextPageHelper() {
    if (nextPage.length > 0) {
      return nextPage;
    }
    if (mediaTypeList.length > 0) {
      var page = mediaTypeList.pop();
      switch (page) {
        case "Videos":
          nextPageValue("/VideoQuestions");
          break;
        case "Music":
          nextPageValue("/MusicQuestions");
          break;
        case "Photos":
          nextPageValue("/PhotoQuestions");
          break;
        case "Email":
          nextPageValue("/EmailQuestions");
          break;
        default:
          break;
      }
    } else {
      nextPageValue("./EndPage");
    }
  }

  function gatherData() {
    var yesIsBusiness =
      "Speak to business partner or employer about work-related social media account";

    var yesIsMonetized =
      "Review rights, interest and ownership in monetized social media account";

    var yesAreContacts =
      "Talk to family and friends about placing notice of death on social media account";

    var IPString =
      "Review intellectual property rights, interest and ownership of content in social media account";

    if (
      yesIsBusinessFlag === true &&
      !tasksToDelegate.includes(yesIsBusiness)
    ) {
      tasksToDelegate.push(yesIsBusiness);
    }
    if (
      yesIsMonetizedFlag === true &&
      !agendaEstateAttorney.includes(yesIsMonetized)
    ) {
      agendaEstateAttorney.push(yesIsMonetized);
    }
    if (
      yesAreContactsFlag === true &&
      !tasksToDelegate.includes(yesAreContacts)
    ) {
      tasksToDelegate.push(yesAreContacts);
    }

    if (yesIsIPFlag === true && !agendaIP.includes(IPString)) {
      agendaIP.push(IPString);
    }
  }

  function initialSocialQuestionHelper(answer) {
    nextPageHelper();
    if (answer === "Business Account") {
      yesIsBusinessVis(true);
      isMonetizedQVis(false);
      continueVisibility(true);
    } else if (answer === "Select") {
      yesIsBusinessVis(false);
      isMonetizedQVis(false);
      continueVisibility(false);
    } else {
      yesIsBusinessVis(false);
      isMonetizedQVis(true);
      continueVisibility(false);
    }
    visHelper(1);
  }

  function monetizeHelper(answer) {
    if (answer === "Yes") {
      yesIsMonetizedVis(true);
      areContactsQVis(true);
    } else if (answer === "No") {
      yesIsMonetizedVis(false);
      areContactsQVis(true);
    } else {
      yesIsMonetizedVis(false);
      areContactsQVis(false);
    }
    visHelper(2);
  }

  function contactHelper(answer) {
    if (answer === "Yes") {
      yesAreContactsVis(true);
      IPQuestionVis(true);
    } else if (answer === "No") {
      yesAreContactsVis(false);
      IPQuestionVis(true);
    } else {
      yesAreContactsVis(false);
      IPQuestionVis(false);
    }
    visHelper(3);
  }

  function IPHelper(answer) {
    if (answer === "Yes") {
      yesIsIPVis(true);
      continueVisibility(true);
    } else if (answer === "No") {
      yesIsIPVis(false);
      continueVisibility(true);
    } else {
      yesIsIPVis(false);
      continueVisibility(false);
    }
  }

  function visHelper(level) {
    switch (level) {
      case 1:
        yesIsMonetizedVis(false);
        areContactsQVis(false);
        yesAreContactsVis(false);
        IPQuestionVis(false);
        yesIsIPVis(false);
        break;
      case 2:
        yesAreContactsVis(false);
        IPQuestionVis(false);
        yesIsIPVis(false);
        continueVisibility(false);
        break;
      case 3:
        yesIsIPVis(false);
        continueVisibility(false);
        break;
      default:
        break;
    }
  }

  const initialSocialMediaQuestion = () => (
    <QuestionSelect
      question={
        "Is the Social Media Account related to a business or personal ?"
      }
      options={["Select", "Business Account", "Personal Account"]}
      function={initialSocialQuestionHelper}
    />
  );

  const yesIsBusinessMessage = () => (
    <TaskListMessage
      task={
        "Speak to business partner or employer about work-related social media account"
      }
    />
  );

  const isMonetizedQuestionComponent = () => (
    <QuestionSelect
      question={"Is the account monetized? (ads, endorsements, etc.)"}
      options={["Select", "Yes", "No"]}
      function={monetizeHelper}
    />
  );

  const yesIsMonetizedMessage = () => (
    <TaskListMessage
      task={"Talk to Estate Attorney about monetized social media account"}
    />
  );

  const areContactsQuestionComponent = () => (
    <QuestionSelect
      question={
        "Do you believe there are contacts (people)  related to this account who you do not have contact information for otherwise?"
      }
      options={["Select", "Yes", "No"]}
      function={contactHelper}
    />
  );

  const yesAreContactsMessage = () => (
    <TaskListMessage
      task={
        "Talk to family and friends about placing notice of death on social media account"
      }
    />
  );

  const IPQuestionComponent = () => (
    <QuestionSelect
      question={"Did the Account contain intellectual property of " + name}
      options={["Select", "Yes", "No"]}
      function={IPHelper}
    />
  );

  const isIPMessage = () => (
    <TaskListMessage
      task={
        "Talk to IP Attorney about Intellectual Property in social media account"
      }
    />
  );

  const continueButton = () => (
    <div class="form-group">
      <NavLink
        to={{
          pathname: nextPage,
          state: {
            p_name: { name },
            tasks: { tasksToDelegate },
            estateTasks: { agendaEstateAttorney },
            IPTasks: { agendaIP },
            ITTasks: { agendaIT },
            socialDeleteAccounts: { socialAccountsToDelete },
            emailDeleteAccounts: { emailAccountsToDelete },
            mediaTypes: { mediaTypeList },
          },
        }}
      >
        <button
          type="button"
          class="btn btn-dark btn-lg"
          onClick={() => gatherData()}
        >
          Continue
        </button>
      </NavLink>
    </div>
  );

  const headerJumbo = () => (
    <div>
      <div class="jumbotron jumbotron-fluid">
        <div class="container">
          <h1 class="display-4">
            Deciding What to Do With Social Media Accounts
          </h1>
          <p class="lead">
            Everyone uses social media accounts differently so there is no
            one-size-fits-all solution or recommendation for what to do with a
            deceased loved one's social media accounts. <br />
            <br />
            Answer the questions below and we will help you flag some important
            decision points and next steps to consider as you decide what to do
            with your loved one's social media accounts.
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {headerJumbo()}
      <div class="col-md-9">
        {initialSocialMediaQuestion()}
        {yesIsBusinessFlag ? yesIsBusinessMessage() : ""}
        {isMonetizedQFlag ? isMonetizedQuestionComponent() : ""}
        {yesIsMonetizedFlag ? yesIsMonetizedMessage() : ""}
        {areContactsQFlag ? areContactsQuestionComponent() : ""}
        {yesAreContactsFlag ? yesAreContactsMessage() : ""}
        {IPQuestionFlag ? IPQuestionComponent() : ""}
        {yesIsIPFlag ? isIPMessage() : ""}
      </div>
      <div class="text-center">{continueBtn ? continueButton() : ""}</div>
    </div>
  );
};
