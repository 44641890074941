import React from "react";

export default function QuestionSelect(props) {
  return (
    <div class="form-group">
      <label for="4">{props.question}</label>
      <select
        class="form-control"
        id="exampleFormControlSelect4"
        onChange={(e) => props.function(e.currentTarget.value)}
      >
        {props.options.map((opns) => (
          <option>{opns}</option>
        ))}
      </select>
    </div>
  );
}
