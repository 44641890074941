import React from "react";
import "bootstrap/dist/css/bootstrap.css";

export default function TaskListMessage(props) {
  return (
    // <div class="form-group">
    //   <p>
    //     We are adding <b> '{props.task}' </b> to your Task List
    //   </p>
    // </div>

    // <div class="card form-group">
    //   <p class="card-header">We are adding the following to your Task List:</p>
    //   <div class="card-body">
    //     <h5 class="card-text">{props.task}</h5>
    //   </div>
    // </div>

    // <div class="card text-center">
    //   <div class="card-header">
    //     We are adding the following to your Task List:
    //   </div>
    //   <div class="card-body">
    //     <h5 class="card-text">{props.task}</h5>
    //   </div>
    //   <div class="card-footer text-muted">Added</div>
    // </div>

    <div class="card text-white bg-info mb-3">
      <div class="card-header">
        <h5>We are adding the following to your Task List:</h5>
      </div>
      <div class="card-body">
        <p class="card-text">{props.task}</p>
      </div>
    </div>
  );
}
