import React from "react";
import { NavLink } from "react-router-dom";

const PreMediaQuestions = (props) => {
  const allPassedProps = props.location.state;
  const name = allPassedProps.p_name.name;
  const tasksToDelegate = allPassedProps.tasks.tasksToDelegate;
  const agendaEstateAttorney = allPassedProps.estateTasks.agendaEstateAttorney;
  const socialAccountsToDelete =
    allPassedProps.socialDeleteAccounts.socialAccountsToDelete;
  const emailAccountsToDelete =
    allPassedProps.emailDeleteAccounts.emailAccountsToDelete;
  var mediaTypeList = allPassedProps.mediaTypes.mediaTypeList;
  const agendaIT = [];
  const agendaIP = [];

  function nextPageHelper() {
    console.log(socialAccountsToDelete);
    if (mediaTypeList.length > 0) {
      mediaTypeList = mediaTypeList[mediaTypeList.length - 1];
      var page = mediaTypeList.pop();
      switch (page) {
        case "Email":
          return "/EmailQuestions";
        case "Videos":
          return "/VideoQuestions";
        case "Music":
          return "/MusicQuestions";
        case "Social Media":
          return "/SocialQuestions";
        case "Photos":
          return "/PhotoQuestions";
        default:
          return;
      }
    } else {
      return "./EndPage";
    }
  }

  const headerJumbo = () => (
    <div>
      <div class="jumbotron jumbotron-fluid">
        <div class="container">
          <p class="lead">
            You will now be prompted with questions for any media types you
            selected in the previous screen before receiving your PDF Task List.
            If no media types were selected, you will be directed directly to
            your Task List.
          </p>
        </div>
      </div>
    </div>
  );

  const continueButtonMediaType = () => (
    <div class="form-group">
      <NavLink
        to={{
          pathname: nextPageHelper(),
          state: {
            p_name: { name },
            tasks: { tasksToDelegate },
            estateTasks: { agendaEstateAttorney },
            IPTasks: { agendaIP },
            ITTasks: { agendaIT },
            socialDeleteAccounts: { socialAccountsToDelete },
            emailDeleteAccounts: { emailAccountsToDelete },
            mediaTypes: { mediaTypeList },
          },
        }}
      >
        <button type="button" class="btn btn-dark btn-lg">
          Continue
        </button>
      </NavLink>
    </div>
  );

  return (
    <div>
      {headerJumbo()}
      <br />
      <div class="text-center">{continueButtonMediaType()}</div>
    </div>
  );
};
export default PreMediaQuestions;
