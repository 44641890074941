import React from "react";

export default function TakeNoteMessage(props) {
  return (
    // <div class="form-group">
    //   <p>
    //     <b>Take Note: </b>
    //     {props.message}
    //   </p>
    // </div>
    // <div class="card form-group">
    //   <h5 class="card-header">Take Note:</h5>
    //   <div class="card-body">
    //     <p class="card-text">{props.message}</p>
    //   </div>
    // </div>
    <div class="card text-white bg-secondary mb-3">
      <div class="card-header">
        <h5>Take Note:</h5>
      </div>
      <div class="card-body">
        {/* <h5 class="card-title">Warning card title</h5> */}
        <p class="card-text">{props.message}</p>
      </div>
    </div>
  );
}
